import React, { Component } from 'react'
import backgroundImage from '../../images/background.jpg'
import repository from '../../images/repository.svg'
import datasets from '../../images/datasets.svg'
import accessibility from '../../images/accessibility.png'
import umdLogo from '../../images/logos/circular_umd.png'
import traceLogo from '../../images/logos/square_trace.png'
import niddilrr from '../../images/logos/logoNidilrr.jpg'
import hcilLogo from '../../images/logos/hcilLogo.png'
import iamLogo from '../../images/logos/iamLogo.png'

import box from "../../images/box.svg"

import CookieConsent from "react-cookie-consent";
// import firstGif from '../../images/landing/firstOne.gif'
//  <img src={firstGif} alt="Go to Search in the navigation bar to search for datasets" />

import firstvid from '../../images/landing/firstvid.mp4'
import firstGif from '../../images/landing/home_gif.gif'
import secondGif from '../../images/landing/secondOne.gif'
//import firstGif from '../../images/landing/home_gif.gif'
import firstVid from '../../images/landing/home_gif.mp4'
import secondVid from '../../images/landing/secondOne.mp4'
//import secondGif from '../../images/landing/secondOne.gif'
import third from '../../images/landing/third.png'
import { PageView, initGA } from '../tracking/GoogleAnalytics'

class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = { windowWidth: window.innerWidth };
    }
    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }
    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    };

    render() {
        initGA('UA-171548325-1')
        PageView()
        const { windowWidth } = this.state;
        const bigPage = (
            <div className='row justify-content-md-center '>
                <div className="col-lg-1 col-md  offset-lg-0 offset-md-1 p-3">
                    <a href="https://iam.umd.edu/">
                        <img src={iamLogo} width="60" height="60" className='img-fluid' alt="IAM Lab logo" />
                    </a>
                </div>
                <div className="col-lg-1 col-md p-3">
                    <a href="https://trace.umd.edu/">
                        <img src={traceLogo} width="60" height="60" className='img-fluid' alt="Trace center logo" />
                    </a>
                </div>
                <div className="col-lg-1 col-md p-3">
                    <a href="https://hcil.umd.edu/">
                        <img src={hcilLogo} width="60" height="60" className='img-fluid' alt="HCI Lab logo" />
                    </a>
                </div>
                <div className="col-lg-1 col-md p-3">
                    <a href="https://umd.edu/">
                        <img src={umdLogo} width="60" height="60" className='img-fluid' alt="UMD Logo" />
                    </a>
                </div>
                <div className="col-lg-2 col-md-3 offset-md-2 pt-2">
                    <a href="https://acl.gov/about-acl/about-national-institute-disability-independent-living-and-rehabilitation-research">
                        <img src={niddilrr} width="150" height="60" className='img-fluid' alt="NIDDILRR Logo" />
                    </a>
                </div>
            </div>
        )

        const smallPage = (
            <div>
                <div className='row justify-content-sm-left'>
                    <div className="col-sm-2 col-3 p-3">
                        <a href="https://iam.umd.edu/">
                            <img src={iamLogo} width="60" height="60" className='img-fluid' alt="IAM Lab logo" />
                        </a>
                    </div>
                    <div className="col-sm-2 col-3 p-3">
                        <a href="https://trace.umd.edu/">
                            <img src={traceLogo} width="60" height="60" className='img-fluid' alt="Trace center logo" />
                        </a>
                    </div>
                    <div className="col-sm-2 col-3 p-3">
                        <a href="https://hcil.umd.edu/">
                            <img src={hcilLogo} width="60" height="60" className='img-fluid' alt="HCI Lab logo" />
                        </a>
                    </div>
                    <div className="col-sm-2 col-3 p-3">
                        <a href="https://umd.edu/">
                            <img src={umdLogo} width="60" height="60" className='img-fluid' alt="UMD Logo" />
                        </a>
                    </div>
                    <div className="col-sm-3 col-5 offset-sm-1 offset-4 p-3">
                        <a href="https://acl.gov/about-acl/about-national-institute-disability-independent-living-and-rehabilitation-research">
                            <img src={niddilrr} height="135" className='img-fluid' alt="NIDDILRR Logo" />
                        </a>
                    </div>
                </div>
            </div>
        )

        return (
            <div className="mx-auto">
                <CookieConsent location="bottom" overlay>
                    This website uses cookies to enhance the user experience.
                </CookieConsent>
                <div className="container mt-5">
                    <div className="row container">
                        <div className="col-md-4 d-flex ">
                            <div className="align-self-baseline mt-1">
                                <div className="row ">
                                    <h3>Explore and contribute to accessibility datasets</h3>
                                </div>
                                <div className="row">
                                    <p>IncluSet is a data surfacing repository enabling researchers and the disability community to
                                        discover and link accessibility datasets.</p>
                                </div>
                                <div className="row">
                                    <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <a className="nav-link pill active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><span className="defcol">Search for a dataset</span></a>
                                        <a className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false"><span className="defcol ">Link a dataset</span></a>
                                        <a className="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false"><span className="defcol">Surface datasets on Google Dataset Search</span></a>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-8">
                            <div className="tab-content" id="v-pills-tabContent">
                                <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                    <video autoPlay loop muted playsinline crossorigin>
                                        <source src={firstVid} alt="Go to Search in the navigation bar to search for datasets" type="video/mp4" />
                                    </video>
                                </div>
                                <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                    <video autoPlay loop muted playsinline crossorigin>
                                        <source src={secondVid} alt="Log in to add new datasets that you collected or found" type="video/mp4" />
                                    </video>
                                </div>
                                <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                    <img src={third} alt="Any datasets accepted would be surfaced to Google Dataset Search for a wider reach" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-5" >
                    <div className="row container">
                        <div className="col-md-auto text-justify">
                            <p class="lead">Inside IncluSet you can find more than 150 existing accessibility datasets. Help the research and disability communities by linking your accessibility project/dataset to IncluSet.</p>

                            <div className="bg-light text-dark rounded-lg">

                                <div className="mt-3 p-4">
                                    Datasets and data sharing play an important role for innovation, benchmarking, mitigating bias, and understanding the complexity of
                                    real world AI-infused applications. However, there is a scarcity of available population data in wellness, accessibility, and aging
                                    due to smaller populations, disparate
                                    characteristics, lack of expertise for data annotation, as well as privacy concerns.

                                    IncluSet stores metadata about where
                                    the datasets can be found, the populations represented, data types, and technology used such that the datasets can be surfaced in engines
                                    like Google Dataset Search. None of the datasets is stored in our server. A download link is only included for those datasets that are publicly available. Datasets that
                                    are available upon request include an email that data creators have indicated and a link to a webpage describing the data when available. To promote transparency, IncluSet also links to datasets that don’t include any sharing intent or information from the creators by
                                    pointing to the related publications where data collection is described.
                                </div>

                            </div>

                            <div className="mt-5 text-justify">

                                <p>
                                    IncluSet was developed at the <a style={{ color: "#000000", textDecoration: "underline" }} href="https://iam.umd.edu/">Intelligent Assistive Machines (IAM) Lab</a> at
                                    the <a style={{ color: "#000000", textDecoration: "underline" }} href="https://umd.edu/">University of Maryland, College Park</a>, which is affiliated with
                                    the <a style={{ color: "#000000", textDecoration: "underline" }} href="https://trace.umd.edu/">Trace R&D Center</a> and <a style={{ color: "#000000", textDecoration: "underline" }} href="https://hcil.umd.edu/">HCIL</a>.
                                    This work is supported by the National Institute on Disability, Independent Living, and Rehabilitation Research
                                    (<a style={{ color: "#000000", textDecoration: "underline" }} href="https://acl.gov/about-acl/about-national-institute-disability-independent-living-and-rehabilitation-research">NIDILRR, ACL, HHS (#90REGE0008)</a>).
                                    The opinions herein are those of the researchers.
                                </p>
                            </div>
                        </div>
                    </div>
                    {(windowWidth > 800) ? bigPage : smallPage}

                </div>
            </div>
        )
    }
}
export default Landing