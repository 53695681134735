import React, {Component} from 'react'
import TextFieldGroup from '../common/TextFieldGroup'
import DropdownFieldGroup from '../common/DropdownFieldGroup'
import Checkbox from '../common/Checkbox'

import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {editDataset, loginGoogleUser} from '../../actions/authActions'
import { GoogleLogin } from 'react-google-login';
import ImageUploader from 'react-images-upload';

class AddDataset extends Component{
    constructor(props){
        super(props)
        const {dataset} = props.auth

        this.state = {
            Dataset_Name: dataset.Dataset_Name,
            Link_Paper: dataset.columns[0].Link_Paper,
            Link_Dataset: dataset.columns[0].Link_Dataset,
            Study_purpose: dataset.columns[0].Study_purpose,
            Data_collection_strategy: dataset.columns[0].Data_collection_strategy,
            Category: dataset.columns[0].Category,
            Sub_category: dataset.columns[0].Sub_category,
            Data_types: dataset.columns[0].Data_types,
            No_of_Participants: dataset.columns[0].No_of_Participants,
            Authors: dataset.columns[0].Authors,
            Contact_Emails: dataset.columns[0].Contact_Emails,
            Strategy: dataset.columns[0].Strategy,
            errors:{},
            options:["Audio","Video","Text","Motion","Image","Logs","Sensing"],
            checkedItems: new Map(),
            pictures: []
        }
        this.onDrop = this.onDrop.bind(this);
        this.handleCheckboxListChange = this.handleCheckboxListChange.bind(this);
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    onDrop(picture) {
        this.setState({
            pictures: this.state.pictures.concat(picture),
        });
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.errors){
          this.setState({errors: nextProps.errors})
        }
      }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }
    responseGoogle = (e) => {
        this.props.loginGoogleUser(e, this.props.history)
      }

    onSubmit = e => {
        e.preventDefault();
        const {user} = this.props.auth
        console.log(user)
        const userData = {
            user: user.googleId,
            Dataset_Name:this.state.Dataset_Name,
            Link_Paper:this.state.Link_Paper,
            Link_Dataset:this.state.Link_Dataset,
            Study_purpose:this.state.Study_purpose,
            Data_collection_strategy:this.state.Data_collection_strategy,
            Category:this.state.Category,
            Sub_category:this.state.Sub_category,
            Data_types:this.state.Data_types,
            No_of_Participants:this.state.No_of_Participants,
            Authors:this.state.Authors,
            Contact_Emails:this.state.Contact_Emails,
            Strategy:this.state.Strategy,

        }
        console.log(userData)
        this.props.editDataset(userData)
      }

      handleCheckboxListChange = (e) => {
        // values is array of selected item. e.g. ['apple', 'banana']
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));

        if (e.target.checked)
            console.log("changed +",e.target.name)
    }

    render(){
        const { errors } = this.state;
        const {isAuthenticated} = this.props.auth
        const {dataset} =  this.props.auth
        if(isAuthenticated)
            return (
            <div className="container">
                <div className="p-2">
                    <h5 className="text-center">Add a new dataset to the repository</h5>
                </div>

                <form onSubmit={this.onSubmit}>
                    <div>
                        <div className="row">
                            <div className="col-2 pb-2">
                                    <ImageUploader
                                        withIcon={true}
                                        singleImage={true}
                                        withPreview={true}
                                        buttonText='Add image'
                                        onChange={this.onDrop}
                                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                        maxFileSize={5242880}
                                        />
                                {/* <div className="  squareSmall mt-auto" style={{borderColor:"#000000"}} >
                                    <div className="cont">
                                        <div className="table">
                                            <input type="file" name="pic" accept="image/*"></input>
                                        </div>
                                    </div>
                                </div> */}
                            </div>

                            <div className="col-10">
                                <div className="row">
                                    <div className="col-12">
                                        <div className=" d-flex justify-content-between mb-n2 mb-n2">
                                            <p className=" "> Name of the dataset</p>
                                            <span className="dot text-center"><p>?</p></span>
                                        </div>
                                        <TextFieldGroup
                                        // placeholder="If the dataset has a name, enter it or enter the paper title"
                                        name="Dataset_Name"
                                        type="text"
                                        value={this.state.Dataset_Name}
                                        onChange={this.onChange}
                                        error={errors.Dataset_Name}
                                        />

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex justify-content-between mb-n2">
                                            <p className=" "> Authors</p>
                                            <span className="dot text-center"><p>?</p></span>
                                        </div>
                                        <TextFieldGroup
                                        // placeholder="Authors names"
                                        name="Authors"
                                        type="emails"
                                        value={this.state.Authors}
                                        onChange={this.onChange}
                                        error={errors.Authors}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex justify-content-between mb-n2">
                                            <p className=" "> Description</p>
                                            <span className="dot text-center"><p>?</p></span>
                                        </div>
                                        <TextFieldGroup
                                        // placeholder="Describe the dataset"
                                        name="Study_purpose"
                                        type="text"
                                        value={this.state.Study_purpose}
                                        onChange={this.onChange}
                                        error={errors.Study_purpose}
                                        />
                                    </div>
                                </div>

                            </div>
                            
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-2">
                            <div className="d-flex justify-content-between mb-n2">
                                <p className=" ">How to access?</p>
                                <span className="dot text-center"><p>?</p></span>
                            </div>
                            <DropdownFieldGroup
                            options={["Download", "Request", "Contact"]}
                            // placeholder="How to download the dataset?"
                            name="Strategy"
                            type="emails"
                            placeholder={this.state.Strategy}
                            onChange={this.onChange}
                            error={errors.Strategy}
                            />
                        </div>
                        {/* <div className="col-2">
                            <div className="d-flex justify-content-between mb-n2">
                                <p className=" ">How to access?</p>
                                <span className="dot text-center"><p>?</p></span>
                            </div>
                            <TextFieldGroup
                            // placeholder="How to download the dataset?"
                            name="Strategy"
                            type="emails"
                            placeholder={this.state.Strategy}
                            onChange={this.onChange}
                            error={errors.Strategy}
                            />
                        </div> */}

                        <div className="col-2">
                            <div className="d-flex justify-content-between mb-n2">
                                <p className=" ">Participants size</p>
                                <span className="dot text-center"><p>?</p></span>
                            </div>
                            <TextFieldGroup
                            // placeholder="Number of Participants"
                            name="No_of_Participants"
                            type="text"
                            placeholder={this.state.No_of_Participants}
                            onChange={this.onChange}
                            error={errors.No_of_Participants}
                            />
                        </div>

                        <div className="col-2">
                            <div className="d-flex justify-content-between mb-n2">
                                <p className=" ">Category</p>
                                <span title="The major category of disability that is used in the project/dataset" className="dot text-center"><p>?</p></span>
                            </div>
                            <DropdownFieldGroup
                            // placeholder="The major category of disability that is used in the project/dataset"
                            options={["Autism","Cognitive","Developmental","Health","Hearing","Learning","Mobility","Speech","Vision"]}
                            name="Category"
                            type="text"
                            placeholder={this.state.Category}
                            onChange={this.onChange}
                            error={errors.Category}
                            />
                        </div>
                        
                        <div className="col-6">
                            <div className="d-flex justify-content-between mb-n2">
                                <p className=" "> Data types</p>
                                <span className="dot text-center"><p>?</p></span>
                            </div>

                            <div className="div_top_hypers"  aria-labelledby="navbarDropdown">
                                <ul className="ul_top_hypers pl-0">
                                    {this.state.options.map((key, index)=>(
                                        <li className="p-1" key={index} style={{listStyleType: "none", borderRadius: "2px"}}>
                                            <Checkbox name={key} placeholder={key} checked={this.state.checkedItems.get(key)} onChange={this.handleCheckboxListChange} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-6">
                            <div className="d-flex justify-content-between mb-n2">
                                <p className=" ">Tags</p>
                                <span className="dot text-center"><p>?</p></span>
                            </div>
                            <TextFieldGroup
                            // placeholder="What other keywords describe the work?"
                            name="Sub_category"
                            type="text"
                            placeholder={this.state.Sub_category}
                            onChange={this.onChange}
                            error={errors.Sub_category}
                            />
                        </div>
                        <div className="col-2">
                            <div className="d-flex justify-content-between mb-n2">
                                <p className=" ">Link to dataset</p>
                                <span className="dot text-center"><p>?</p></span>
                            </div>
                            <TextFieldGroup
                            // placeholder="Link to the dataset"
                            name="Link to the dataset"
                            type="text"
                            placeholder={this.state.Link_Dataset}
                            onChange={this.onChange}
                            error={errors.Link_Dataset}
                            />
                        </div>

                        <div className="col-2">
                            <div className="d-flex justify-content-between mb-n2">
                                <p className=" "> Link to paper</p>
                                <span className="dot text-center"><p>?</p></span>
                            </div>
                            <TextFieldGroup
                            // placeholder="Link to the paper"
                            name="Link_Paper"
                            type="text"
                            placeholder={this.state.Link_Paper}
                            onChange={this.onChange}
                            error={errors.Link_Paper}
                            />
                            </div>                        

                        <div className="col-2">
                            <div className="d-flex justify-content-between mb-n2">
                                <p className=" "> Contact</p>
                                <span className="dot text-center"><p>?</p></span>
                            </div>
                            <TextFieldGroup
                            // placeholder="Contact emails or any other contact for the obtaining the dataset"
                            name="Contact_Emails"
                            type="emails"
                            placeholder={this.state.Contact_Emails}
                            onChange={this.onChange}
                            error={errors.Contact_Emails}
                            />
                        </div>
                    </div>

                    <input type="submit" className="btn btn-info mt-4" placeholder="Update dataset" />
                </form>
            </div>
            )
        else
            return (
                <div className="container">
                    <div className="row justify-content-md-center">
                    <GoogleLogin
                        clientId="915080396743-6nhp55re256cvhl9jah28lncndibal16.apps.googleusercontent.com"
                        buttonText="Login With google to add datasets"
                        onSuccess={this.responseGoogle}
                        cookiePolicy={'single_host_origin'}
                    /></div>
                </div>)
    }
}
const mapStatesToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
  })

export default connect(mapStatesToProps, {editDataset, loginGoogleUser})(withRouter(AddDataset))