import {GET_ERRORS, REGISTER_USER, SET_CURRENT_USER, SET_CURRENT_USER_SIMPLE, LOGOUT_USER, 
    UPDATE_PASSWORD, CHECK_TOKEN, CHECK_EMAIL, CHECK_TOKEN_FAIL,
    UPDATE_PASSWORD_FAIL, BAD_DATASET, EDIT_DATASET, MY_DATASETS, EDIT_DATASET_FAIL,
    UPDATE_DATASET, UPDATE_DATASET_FAIL, ADDED_DATASET, VOTE_DATASET, VOTE_DATASET_FAIL} from './types'
import axios from 'axios'
import setAuthToken from '../utils/setAuthToken';
import swal from 'sweetalert';

export const downloadDatasets = () => {
    axios
    .get("/api/datasets/all")
    .then(res=>{
        console.log(res.data)
        return res.data
    })
    .catch(err=>console.log(err.response.data))
}

export const addDataset = (data, history) => dispatch => {
    axios
    .post("/api/datasets/add", data)
    .then(res=>{
        console.log(res.data)
        dispatch({
            type: ADDED_DATASET,
            payload: {}
        })
        swal('Success! You can add another Dataset!')
        history.push("/")
        // return res.data
    }).catch(err => {
        console.log(err)
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    }
        )
}

export const editDataset = (datasetName, history) => dispatch => {
    console.log("DONE", datasetName)
    axios
    .post("/api/datasets/editDataset", {"Dataset_Name": datasetName})
    .then(res=>{
        console.log(res.data, datasetName)
        dispatch({
            type: EDIT_DATASET,
            payload: res.data
        })
        history.push("/editDataset")
    })
    .catch(err=>{
        console.log(err)

        dispatch({
            type: EDIT_DATASET_FAIL,
            payload: err.response.data
        })
    })
}

export const updateDataset = (datasetName, key, newVal) => dispatch => {
    console.log("DONE", key, newVal, datasetName)
    let payload = {"Dataset_Name": datasetName, "key": key, "value": newVal}
    return axios
    .post("/api/datasets/updateDataset", payload)
    .then(res=>{
        console.log(res.data, datasetName)
        dispatch({
            type: UPDATE_DATASET,
            payload: res.data
        })
    })
    .catch(err=>{
        dispatch({
            type: UPDATE_DATASET_FAIL,
            payload: err.response.data
        })
    })
}

export const myDatasets = (email) => dispatch => {
    axios
    .post("/api/datasets/mydata", {"email":email})
    .then(res=>{
        console.log(res.data)
        dispatch({
            type: MY_DATASETS,
            payload: res.data
        })
    })
    .catch(err=>{
        console.log(err)
        dispatch({
            type: BAD_DATASET,
            payload: err.response.data
        })
    })
    console.log("DONE", email)
}

// LOGIN APIs
export const addNewUser = (userData, history) => dispatch =>{
    axios
    .post("/api/users/register", userData)
    .then(res=>{
        // console.log(res.data)
        dispatch({
            type: REGISTER_USER,
            payload: {}
        })
        // Shows a pop up with with a success message
        swal('Success! You can login now, click the tab on the right')
        history.push("/login")
    }).catch(err => 
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
}

export const checkToken = (token) => dispatch => {
    console.log("sent",token)
    axios
    .post("/api/users/reset", token)
    .then(res=>{
        console.log(res.data)
        dispatch({
            type: CHECK_TOKEN,
            payload: true
        })
    }).catch(err => 
        dispatch({
            type: CHECK_TOKEN_FAIL,
            payload: err.response.data
        }))
}

export const checkEmail = (userData) => dispatch => {
    axios
    .post("/api/users/forgot", userData)
    .then(res=>{
        console.log(res.data)
        dispatch({type: CHECK_EMAIL})
    }).catch(err => 
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
}

export const updatePassword = (userData, history) => dispatch => {
    // userData has password, password2, token
    axios
    .post("/api/users/updatePassword", userData)
    .then(res=>{
        console.log(res.data)
        dispatch({
            type: UPDATE_PASSWORD,
            payload: userData
        })
        history.push("/login")
    }).catch(err => 
        dispatch({
            type: UPDATE_PASSWORD_FAIL,
            payload: err.response.data
        }))
}

export const loginUser = (userData, history) => dispatch => {
    axios
    .post("/api/users/login", userData)
    // .then(res => console.log(res.data))
    .then(res=>{
        console.log(res)
        setAuthToken(res.data.token)
        dispatch({
            type: SET_CURRENT_USER_SIMPLE,
            payload: {"user": res.data.user, "isGod": res.data.isGod}
        })
        history.push("/")
    })
    .catch(err => 
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
}

export const loginGoogleUser = (data, history) => dispatch => {
    localStorage.setItem('jwtToken', JSON.stringify(data))
    
    // console.log(data)
    setAuthToken(data.accessToken)
    dispatch({
        type: SET_CURRENT_USER,
        payload: data
    })
    axios.post('/api/users/loginGoogle', data.profileObj)
    .then(console.log("addeds"))
    .catch(err=>console.log(err))
}

export const logoutUser = (history) => dispatch => {
    localStorage.removeItem('jwtToken')
    setAuthToken(false)
    dispatch({
        type: LOGOUT_USER,
        payload: {}
    })
    history.push("/")
}

export const voteDataset = (datasetName, userName) => dispatch => {
    return axios
    .post("/api/datasets/voteDataset", {"Dataset_Name": datasetName, "username": userName})
    .then(res=>{
        console.log(res);
        dispatch({
            type: VOTE_DATASET,
            payload: res.data
        })
    })
    .catch(err=>{
        console.log(err)

        dispatch({
            type: VOTE_DATASET_FAIL,
            payload: err.response.data
        })
    })
}