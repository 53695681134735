import React, {Component} from 'react'
import TextFieldGroup from '../common/TextFieldGroup'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {loginUser, loginGoogleUser} from '../../actions/authActions'
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import {Link} from 'react-router-dom' 
import GoogleIcon from '../../images/Google.svg'

class Login extends Component{
  constructor(){
      super()
      // Login state 
      this.state = {
          name: '',
          password: '',
          errors: {},
          hidden: false
      }
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.auth.isAuthenticated){
      this.props.history.push("/")
    }
    if(nextProps.errors){
      this.setState({errors: nextProps.errors})
    }
  }

  onSubmit = e => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password
    };

    this.props.loginUser(userData, this.props.history)
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  logout = (e)=>{
    this.props.logoutUser()
  }
  
  responseGoogle = (e) => {
    this.props.loginGoogleUser(e, this.props.history)
  }

  hide = () => {
    this.setState({hidden: true})
  }

  render(){
    const {errors} = this.props
    // Get the user from state like this
    // const {user} = this.props.auth
    const loginButtons = (
      <div className="row justify-content-center mt-2"> 
              <GoogleLogin
                  clientId="915080396743-6nhp55re256cvhl9jah28lncndibal16.apps.googleusercontent.com"
                  render={renderProps => (
                    <button className="btn-block button p-2" onClick={renderProps.onClick} disabled={renderProps.disabled}> 
                      Login with Google</button>
                  )}
                  buttonText="Login with Google" className="btn-block button" style={{fontSize:"40px"}}
                  onSuccess={this.responseGoogle}
                  cookiePolicy={'single_host_origin'}/> 
              <button type="button" className="button btn-block p-2" onClick={this.hide}>
                <span className="p-3 justify-content-left">
                Sign In with Email
                </span>
              </button>
              </div>
    )

    const form = (
      <div className="mt-2">
        <form onSubmit={this.onSubmit}>
          <TextFieldGroup
            placeholder="Login with Email"
            name="email"
            type="email"
            value={this.state.email}
            onChange={this.onChange}
            error={errors.email}
          />
          <TextFieldGroup
            placeholder="Password"
            name="password"
            type="password"
            value={this.state.password}
            onChange={this.onChange}
            error={errors.password}
          />
          <input type="submit" value="Login here" className="p-2 btn button btn-block mt-2" />
          
        </form>
          <Link to="/forgot">
            <button className="p-2 mt-2 btn button btn-block">Forgot password?</button>
          </Link>
      </div>
    )
  return (
      <div className="login">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              {this.state.hidden ? form : loginButtons}              
            </div>
          </div>
          
        </div>
      </div>
  )}
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStatesToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(mapStatesToProps, {loginUser, loginGoogleUser})(withRouter(Login))