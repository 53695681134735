import React, {Component} from 'react'
import {Tabs, Tab} from "react-bootstrap"
import Login from '../auth/Login'
import Register from '../auth/Register'

class CombinedLogin extends Component{
    render () {
        return(
            <div className="container mx-auto" style={{marginTop:"54px"}}>
                <div className="row" >
                    <div className="mx-auto">
                        <Tabs className="tabs nav-fill" defaultActiveKey="dd" id="uncontrolled-tab-example">
                            <Tab eventKey="dd" title="Login">
                                <Login/>
                            </Tab>
                            <Tab eventKey="ed" title="Signup">
                                <Register/>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}

export default CombinedLogin