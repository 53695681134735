import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Checkbox from './Checkbox';

const CheckboxGroup = ({
  items,
  error,
  info,
  labelledby,
  checkeditems,
  onChange,
}) => {
  return (
    <div className="form-group">
      {/* <select
      type={type}
      className={classnames('form-control form-control-lg', {
        'is-invalid': error
      })}
      style={{fontSize:"12px", height:"32px"}}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      disabled={disabled}
      >
        {generatedOptions}
      </select> */}
      <div className={classnames('div_top_hypers', {
        'is-invalid': error
      })} aria-labelledby="navbarDropdown">
            <ul aria-labelledby={labelledby} className="ul_top_hypers pl-0">
                {items.map((key, index)=>(
                    <li className="p-1" key={index} style={{listStyleType: "none", borderRadius: "2px"}}>
                        <Checkbox name={key} value={key} checked={checkeditems.get(key)} onChange={onChange} />
                    </li>
                ))}
            </ul>
        </div>
      {info && <small className="form-text text-muted">{info}</small>}
      {error && <div className="invalid-feedback" aria-live="polite" role="alert">{error}</div>}
    </div>
  );
};

CheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
  labelledby: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.string
};

CheckboxGroup.defaultProps = {
  type: 'text'
};

export default CheckboxGroup;