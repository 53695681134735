import React, { Component } from 'react'
import Checkbox from '../common/Checkbox'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Search from "../../images/search.svg"
import Close from "../../images/close.svg"

class Filters extends Component {
    constructor() {
        super()
        this.state = {
            lists: {
                Data_Type: [
                    'Audio', 'Image', 'Logs', 'Motion', 'Sensing', 'Text', 'Video'
                ],
                Population: [
                    'Autism', 'Cognitive', 'Developmental', 'Health', 'Hearing', 'Language', 'Learning', 'Mobility', 'Speech', 'Vision'
                ]
            },
            checkedItems: new Map(),
            searchValue: ''
        }
        this.state.keys = Object.keys(this.state.lists)
        this.handleCheckboxListChange = this.handleCheckboxListChange.bind(this);

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.checkedItems && nextProps.filtersUpdated) {
            this.props.resetFilters();
            nextProps.checkedItems.forEach((key, value) => {
                let item = value;
                let isChecked = key;
                console.log(nextProps.checkedItems)
                if (!isChecked) {
                    console.log("hm")
                    this.handleCheckboxListChange(item, isChecked);
                }
            })
        }


    }

    getDataTypeMap(item) {
        const boolToType = ['Audio', 'Image', 'Logs', 'Motion', 'Sensing', 'Text', 'Video']
        let dataTypesArr = []
        boolToType.forEach((type, idx) => {
            if (item[type] === "1") {
                dataTypesArr.push(type)
            }
        })
        return dataTypesArr
    }


    doFilter = (item) => {
        const dataTypes = this.getDataTypeMap(item[0])

        const { Data_Type } = this.state.lists
        const { Population } = this.state.lists

        let found = false

        let anyDatatypes = false
        let foundDatatypes = []
        this.state.checkedItems.forEach((checked, type) => {
            if (Data_Type.includes(type) && !anyDatatypes)
                anyDatatypes = true
            if (dataTypes.includes(type) && checked) {
                console.log("FOUND", dataTypes, dataTypes.includes(type), type)
                foundDatatypes.push(type)
                found = true;
            }
        })
        // if foundDataypes.length!==0 --> weak match
        let anyCategory = false;
        let foundCategory = false;
        this.state.checkedItems.forEach((checked, type) => {
            if (Population.includes(type) && !anyCategory)
                anyCategory = true
        })
        if ((foundDatatypes.length !== 0 && anyDatatypes) || (!anyDatatypes || anyCategory))
            this.state.checkedItems.forEach((checked, type) => {

                // if(type===item[0].Category && checked){
                //     console.log("DONE_categories", item[0].Category, type)
                //     found = true;
                //     foundCategory = true;
                // }
                if (item[0][type] === "1" && checked) {
                    console.log("DONE_categories", item[0].Category, type)
                    found = true;
                    foundCategory = true;
                }
            })
        if (!foundCategory && anyCategory)
            found = false;
        if (foundDatatypes.length === 0 && anyDatatypes)
            found = false;
        // console.log(found, anyDatatypes, anyCategory, foundDatatypes, foundDatatypes.length!==0, item[0].Data_types, item[0].Category)

        return found
    }

    getCatTypeMap = (item) => {
        const boolToType = ['Autism', 'Cognitive', 'Developmental', 'Health', 'Hearing', 'Language', 'Learning', 'Mobility', 'Speech', 'Vision']
        let dataTypesArr = []
        boolToType.forEach((type, idx) => {
            if (item[type] === "1") {
                dataTypesArr.push(type)
            }
        })
        return dataTypesArr
    }

    findMatch = (list) => {
        let set = false
        this.state.checkedItems.forEach((checked, type) => {
            if (list.includes(type) && checked)
                set = true
        })
        // console.log(list, this.state.checkedItems, set)
        return set
    }

    doFilterNew = (item) => {
        const { Data_Type } = this.state.lists
        const { Population } = this.state.lists

        // datatype in item
        const dataTypes = this.getDataTypeMap(item[0])
        // category in item
        const catTypes = this.getCatTypeMap(item[0])
        let found = false

        let onlycat = false
        let onlytype = false
        let both = false
        this.state.checkedItems.forEach((checked, type) => {
            // check if checked item is onlycat, onlytype or both
            if (Population.includes(type) && checked)
                onlycat = true
            if (Data_Type.includes(type) && checked)
                onlytype = true
        })
        if (onlycat && onlytype)
            both = true

        if (onlycat && !both)
            found = this.findMatch(catTypes)
        else if (onlytype && !both)
            found = this.findMatch(dataTypes)
        else {
            let foundCat = this.findMatch(catTypes)
            let foundType = this.findMatch(dataTypes)
            if (foundCat && foundType)
                found = true
        }

        return found
    }

    handleCheckboxListChange = (item, isChecked) => {
        // values is array of selected item. e.g. ['apple', 'banana']
        // const item = e.target.name;
        // const isChecked = e.target.checked;
        let mapCopy = this.state.checkedItems
        // this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
        console.log(this.state.checkedItems, this.props.items)
        mapCopy.set(item, isChecked)
        let atleast1true = false

        mapCopy.forEach((checked, type) => {
            if (checked) {
                atleast1true = true
            } else {
                mapCopy.delete(type);
            }
        })
        if (this.props.items.length === 0 && !atleast1true) {
            this.props.resetItems()
        }
        if (atleast1true) {
            this.setState({ checkedItems: mapCopy })
        }
        else {
            this.props.resetItems()
        }

        console.log("CHCK", this.state.checkedItems.size, this.props.copyItems, this.state.checkedItems.get(item), atleast1true)

        // if (this.state.checkedItems.size === 1 && !this.state.checkedItems.get(item) && !atleast1true) {
        //     console.log("TRigg")
        //     this.props.resetItems()
        // }
        if (this.state.checkedItems.size > 0) {
            let newItems = []
            this.props.copyItems.forEach(singleItem => {
                if (this.doFilterNew(singleItem)) {
                    newItems.push(singleItem)
                }
            })

            this.props.updateItems(newItems, this.state.checkedItems)

        } else {
            this.props.updateItems(this.props.copyItems, this.state.checkedItems)
        }
    }



    tickOrNot = name => {
        if (this.state.checkedItems.get(name) === undefined)
            return false
        else
            return this.state.checkedItems.get(name)
    }


    isCategoryFiltered = (key) => {
        let mapCopy = this.state.checkedItems
        let isCategoryFiltered = false;
        mapCopy.forEach((checked, type) => {
            if (this.state.lists[key].indexOf(type) >= 0 && checked) {
                isCategoryFiltered = true;
            }
        })
        if (!isCategoryFiltered)
            return 'false'
        else
            return 'true'
    }

    clearCategoryFilter = (key) => {
        let mapCopy = this.state.checkedItems
        mapCopy.forEach((checked, type) => {
            if (this.state.lists[key].indexOf(type) >= 0 && checked) {
                this.state.checkedItems.set(type, false)
                this.setState({ checkedItems: this.state.checkedItems })
                this.handleCheckboxListChange(type, false);
            }
        })

    }


    render() {
        return (
            <div className="d-flex align-items-start flex-column" style={{ height: "500px" }}>

                <div className="p-1">
                    <p style={{ fontWeight: '700', fontSize: '16px' }}>Filter By: </p>
                    {this.state.keys.map((key, index) => (
                        <ul key={index} className="p-0">
                            <div style={{ marginBottom: "16px" }}>
                                <span className="tabs" style={{ fontWeight: '600' }}>{key.replace("_", " ")}</span>
                                <span style={{ marginLeft: "8px" }}>
                                    {(key === 'Data_Type' && this.state.checkedItems.size > 0 && this.isCategoryFiltered('Data_Type') === 'true') ? (
                                        <img className="svgLabel pr-1" src={Close} role="button" role="button" onClick={() => this.clearCategoryFilter(key)} alt="Remove Filter button" title="Remove Filter button" />
                                    ) : ""}
                                    {(key === 'Population' && this.state.checkedItems.size > 0 && this.isCategoryFiltered('Population') === 'true') ? (
                                        <img className="svgLabel pr-1" src={Close} role="button" onClick={() => this.clearCategoryFilter(key)} alt="Remove Filter button" title="Remove Filter button" />
                                    ) : ""}
                                </span>
                            </div>
                            {this.state.lists[key].map((name, idx) => (
                                <li key={idx} style={{ listStyleType: "none", color: "#000000", borderRadius: "2px" }}>
                                    <Checkbox name={name} checked={this.tickOrNot(name)} onChange={(e) => this.handleCheckboxListChange(e.target.name, e.target.checked)} />
                                </li>
                            ))}
                        </ul>
                    ))}
                </div>
                {/* <button style={{fontSize:"14px"}} className="button p-1" onClick={this.resetCheckbox}>Reset</button> */}
                {/* <button className="button p-1" onClick={this.resetCheckbox}>
                    <img className="svgLabel pr-1" src={Reset} alt="Reset button" title="Reset button" />
                    <span>Reset</span>
                </button> */}
            </div>
        )
    }
}
export default Filters;