/* eslint-disable no-fallthrough */
import {REGISTER_USER, SET_CURRENT_USER, SET_CURRENT_USER_SIMPLE, LOGOUT_USER, CHECK_TOKEN, UPDATE_PASSWORD, 
    CHECK_TOKEN_FAIL, UPDATE_PASSWORD_FAIL, EDIT_DATASET, MY_DATASETS, BAD_DATASET, ADDED_DATASET, VOTE_DATASET} from '../actions/types'
import isEmpty from '../validation/is-empty'

const initialState = {
    isAuthenticated: false,
    user: {},
    loginType: "Google",
    resetPassword: false
}

export default function(state = initialState, action){
    switch (action.type){

        case REGISTER_USER:
            return{
                ...state
            }

        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload.profileObj,
                loginType: "Google"
            }
        case SET_CURRENT_USER_SIMPLE:
            console.log(action)
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload.user,
                loginType: "Email",
                isGod: action.payload.isGod
            }
        case LOGOUT_USER:
            console.log(action)
            return {
                ...initialState
            }
        case CHECK_TOKEN:
            console.log(action.payload)
            return{
                ...state,
                isAuthenticated: false,
                user: {},
                loginType: "",
                resetPassword: true
            }    
        case UPDATE_PASSWORD:
            console.log(action.payload)
            return{
                ...state,
                isAuthenticated: false,
                user: {},
                loginType: "",
                resetPassword: true
            }    
        case CHECK_TOKEN_FAIL:
            console.log(action.payload)
            return{
                ...state,
                resetPassword: false
            }
        case UPDATE_PASSWORD_FAIL:
            console.log(action.payload)
            return{
                ...state,
                resetPassword: false
            }
        case EDIT_DATASET:
            console.log("payload", action.payload)
            return{
                ...state,
                resetPassword: false,
                dataset:action.payload
            }
        
        case ADDED_DATASET:
            console.log("payload", action.payload)
            return{
                ...state,
                resetPassword: false,
                dataset:action.payload
            }   
        case MY_DATASETS:
            console.log("payload", action.payload)
            return{
                ...state,
                resetPassword: false,
                myDatasets: action.payload
            }
        case BAD_DATASET:
                console.log("payload", action.payload)
        case VOTE_DATASET:
            console.log("PAYLOAD", action.payload)
            let newVal ={ 
                ...state,
                resetPassword: false,
                dataset: action.payload};
            console.log("returnValue",newVal)
            return newVal
        default:
            return state
    }
}