import React, {Component} from 'react'
import {Link, NavLink} from 'react-router-dom' 
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {loginGoogleUser, logoutUser} from '../../actions/authActions'
import { GoogleLogout } from 'react-google-login';

class Navbar extends Component {
    constructor(){
        super()
        this.responseGoogle = this.responseGoogle.bind(this)
        this.logout = this.logout.bind(this)
        this.handleClick = this.handleClick.bind(this)
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.errors){
          this.setState({errors: nextProps.errors})
        }
      }
      logout = (e)=>{
        this.props.logoutUser(this.props.history)
      }
    responseGoogle = (e) => {
        this.props.loginGoogleUser(e, this.props.history)
    }
    handleClick = () => {
        console.log(window.location.href);
        if (window.location.href.includes("/login"))
            window.location.reload()
    }
    render(){
        const {isAuthenticated, loginType} = this.props.auth

        const authLinks = (
        <li className="nav-item">
            <NavLink activeClassName="thisactive" className="nav-link" to="/login" onClick={this.handleClick} >
                Login
            </NavLink>
            </li>
        )
        const logout = (<li className="nav-item">
            <Link className="nav-link" to="/" onClick={this.logout}>
                Logout
            </Link>
            </li>)

        const googleLogout = (<li className="nav-item">
            <GoogleLogout
                clientId="915080396743-6nhp55re256cvhl9jah28lncndibal16.apps.googleusercontent.com"
                render={renderProps => (
                    <Link className="nav-link" to="/" onClick={renderProps.onClick} disabled={renderProps.disabled}>Logout</Link>
                  )}
                onLogoutSuccess={this.logout}
            />
            </li>
        )

        const addDataset = (
            <li className="nav-item mr-3">
                <NavLink className="nav-link" activeClassName="thisactive" to="/add">
                Add Dataset
                </NavLink>
            </li>
        )

        return (
            <nav className="navbar navbar-expand-sm header text-white" >
                <div className="container">
                    <Link className="navbar-brand" style={{fontWeight: 900}} to="/">IncluSet</Link>
                    <button className="navbar-toggler" style={{borderColor:"rgb(255,255,255)", color:"#FFFFFF"}} type="button" data-toggle="collapse" data-target="#mobile-nav" aria-controls="mobile-nav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="mobile-nav">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <NavLink exact className="nav-link" activeClassName="thisactive"  to="/datasets"> Search </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" activeClassName="thisactive" to="/about"> About </NavLink>
                            </li>
                        </ul>
                        <ul className="navbar-nav ml-auto">
                        {isAuthenticated
                                ? addDataset
                                : <div></div>}   

                            {isAuthenticated
                                ? loginType==="Google"
                                    ? googleLogout
                                    : logout
                                : authLinks}                            
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}

const mapStatesToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
  })

export default connect(mapStatesToProps, {loginGoogleUser, logoutUser})(withRouter(Navbar))