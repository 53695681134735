import React, { Component } from 'react'
import TextFieldGroup from '../common/TextFieldGroup'
import {addNewUser} from '../../actions/authActions'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

class Register extends Component {
    constructor(){
        super()
        this.state = {
            name: '',
            email: '',
            password: '',
            password2: '',
            errors: {},
        }

        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    componentWillReceiveProps(nextProps){
      if(nextProps.auth.isAuthenticated){
        this.props.history.push("/")
      }
      if(nextProps.errors){
        this.setState({errors: nextProps.errors})
      }
    }

    onSubmit = e => {
        e.preventDefault();
        const newUser = {
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            password2: this.state.password2
        };
        this.props.addNewUser(newUser, this.props.history)
    }

    render (){
        const { errors } = this.state;

    return (
      <div className="register">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <p className="lead text-center">
                Create your IncluSet account
              </p>
              <form noValidate onSubmit={this.onSubmit}>
                <TextFieldGroup
                  placeholder="Name"
                  name="name"
                  value={this.state.name}
                  onChange={this.onChange}
                  error={errors.name}
                />
                <TextFieldGroup
                  placeholder="Email"
                  name="email"
                  type="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  error={errors.email}
                //   info="This site uses Gravatar so if you want a profile image, use a Gravatar email"
                />
                <TextFieldGroup
                  placeholder="Password"
                  name="password"
                  type="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  error={errors.password}
                />
                <TextFieldGroup
                  placeholder="Confirm Password"
                  name="password2"
                  type="password"
                  value={this.state.password2}
                  onChange={this.onChange}
                  error={errors.password2}
                />
                <input type="submit" value="Make account" className="btn button btn-block mt-2 p-2" />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(mapStatesToProps, {addNewUser})(withRouter(Register))