import React, { Component } from 'react'
import TextFieldGroup from '../common/TextFieldGroup'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {checkEmail} from '../../actions/authActions'
import PropTypes from 'prop-types'

class ForgotPassword extends Component{
    constructor(){
        super()
        this.state={
            email:'',
            errors:{},
            messageFromServer:""
        }
    }
    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }
    onSubmit = e => {
        e.preventDefault()
        const userData = {
            email: this.state.email
          };
        this.setState({errors:{}});
        this.props.checkEmail(userData)
    }
    render(){
        const {errors} = this.props
        // Get the user from state like this
        // const {user} = this.props.auth
      return (
          <div className="login">
            <div className="container">
              <div className="row justify-content-center mt-2">
                <div className="m-auto">
                  
                  <div className="row justify-content-center"> 
                    <span className="text-center">Forgot Password?</span>
                  </div>
                  <p className=" text-center">
                    We'll send you an email to reset your password
                  </p>
                  <form onSubmit={this.onSubmit}>
                    <TextFieldGroup
                      placeholder="Enter registered email"
                      name="email"
                      type="email"
                      value={this.state.email}
                      onChange={this.onChange}
                      error={errors.email}
                    />
                    <input type="submit" className="p-1 btn button btn-block mt-4" value="Reset password" />
                  </form>
                </div>
              </div>
              
            </div>
          </div>
      )}
}

ForgotPassword.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  }
  
  const mapStatesToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
  })

export default connect(mapStatesToProps, {checkEmail})(withRouter(ForgotPassword))