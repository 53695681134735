import React, {Component} from 'react'

class CategoryImg extends Component{

    /**
     * Function that maps the color of the box to the type of category, multiple categories are also supported
     * 
     * @name categoryColor
     * @param category {string} name of the category, "Multi" if multiple categories
     */
    categoryColor = category => {
        const colorMap = {
            "Learning":"#ffe119",
            "Speech":"#4363d8",
            "Health":"#f58231",
            "Autism":"#e6beff",
            "Developmental":"#800000",
            "Hearing":"#fabebe",
            "Mobility":"#000075",
            "Vision":"#42d4f4",
            "Language":"#469990",
            "Cognitive":"#fffac8",
            "Multi": "#C0C0C0"
        }
        return colorMap[category]
    }
    render(){
        // Props are passed for each dataset json
        let {category} = this.props;
        let text = ""

        // Checking for number of categories, multiple categories will be renamed as "Multi"
        if(category.length > 1){
            text = category.join("\n")
            category = "Multi"
            // console.log(text)
        }
        else{
            category = category[0]
            text = category
        }
        // Return the box with the correct color for the category
        return(
            <div className=" square mt-auto" style={{borderColor:this.categoryColor(category)}} >
                <div className="cont">
                    <div className="table">
                        <p className="text" >{text}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default CategoryImg;