import React, { Component } from 'react'
// import DatasetCard from '../datasets/DatasetCard'
import Filter from '../layout/Filter'
import axios from 'axios'
import { Tabs, Tab } from "react-bootstrap"
import CardsGen from "../datasets/CardGen"
import { ClipLoader } from 'react-spinners';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { myDatasets } from '../../actions/authActions'
import { PageView, initGA } from '../tracking/GoogleAnalytics'
import DropdownFilters from './DropdownFilter'
import Close from "../../images/close.svg"
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Search from "../../images/search.svg"
import * as _ from 'lodash'

class Datasets extends Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [
                /**
                 * @todo Update to latest
                 * Example dataset with all columns 
                 */
                //     {heading: "WebinSitu: a comparative analysis of blind and sighted browsing behavior",
                //     authors: "Bigham, Cavender, Brudvik, Wobbrock and Ladner",
                //     baseText: "Comparision of browsing behaviours between blind and sighted participants",
                //     strategy: "DIRECT DOWNLOAD",
                //     dataTypes: "Logs",
                //     extraText: "An HTTP proxy connection monitored and collected all the user interactions with webpages for a week, in a database for futher analysis",
                //     category: "Vision",
                //     subcategory: "Blind",
                //     participants: "10"},
                //     {heading: "WebinSitu: a comparative analysis of blind and sighted browsing behavior",
                //     authors: "Bigham, Cavender, Brudvik, Wobbrock and Ladner",
                //     baseText: "Comparision of browsing behaviours between blind and sighted participants",
                //     strategy: "DIRECT DOWNLOAD",
                //     dataTypes: "Logs",
                //     extraText: "An HTTP proxy connection monitored and collected all the user interactions with webpages for a week, in a database for futher analysis",
                //     category: "Vision",
                //     subcategory: "Blind",
                //     participants: "10"}
            ],
            errors: {},
            newItems: [],
            copyItems: [],
            myDatasets: [],
            checkedItems: [],
            filteredDataFound: false,
            filtersUpdated: undefined,
            loadingDatasets: true,
            windowWidth: window.innerWidth
        }
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors })
        }
        if (nextProps.items) {
            this.setState({ newItems: nextProps.items })
        }
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentDidMount() {
        this.setState({ loadingDatasets: true });
        axios
            .get("/api/datasets/all")
            .then(res => {
                const items = []
                console.log(res.data)
                res.data.map((item) => {
                    var year = new Date(item.Year_Of_Release)
                    items.push({
                        published: item.published,
                        accepted: item.accepted,
                        Dataset_Name: item.Dataset_Name,
                        uploadDate: year.getFullYear(),
                        votes: item.votes,
                        ...item.columns,
                        votedBy: item.votedBy
                    })
                    return null
                })
                this.setState({ items: items })
                this.setState({ copyItems: items })
                this.setState({ loadingDatasets: false });
                console.log(this.state.loadingDatasets)
            })
            .catch(err => console.log("ERR", err))
        const { isAuthenticated } = this.props.auth
        let myDatasets = [];
        if (isAuthenticated) {
            myDatasets = this.props.myDatasets(this.props.auth.user.email)
            this.setState({ myDatasets: myDatasets })
            if (myDatasets !== undefined && myDatasets.length !== 0) {
                this.setState({ myDatasets: myDatasets })
            }
        }
        initGA('UA-171548325-1')
        PageView()
        window.addEventListener("resize", this.handleResize);
    }

    removeFilter = (filterValue) => {
        console.log("FilterValue", filterValue)
        this.state.checkedItems.set(filterValue, false)
        this.setState({ checkedItems: this.state.checkedItems })

        this.setState({ filtersUpdated: true });
    }
    getDatasets() {
        this.setState({ loadingDatasets: true });
        axios
            .get("/api/datasets/all")
            .then(res => {

                const items = []
                res.data.map((item) => {
                    var year = new Date(item.Year_Of_Release)
                    items.push({
                        Dataset_Name: item.Dataset_Name,
                        uploadDate: year.getFullYear(),
                        votes: item.votes,
                        ...item.columns
                    })
                    return null
                })
                this.setState({ items: items })
                this.setState({ copyItems: items })
                this.setState({ loadingDatasets: false });
                console.log(this.state.loadingDatasets)
            })
            .catch(err => console.log(err.response.data))
    }

    resetItems = () => {
        this.state.checkedItems.forEach((key, value) => {

            this.state.checkedItems.set(value, false)
            this.setState({ checkedItems: this.state.checkedItems })
        })
        this.setState({ checkedItems: new Map() })
        console.log("reset items", this.state.searchValue)
        if (this.state.searchValue) {
            this.setState({ loadingDatasets: true });
            axios
                .get("/api/datasets/all")
                .then(res => {

                    const items = []
                    res.data.map((item) => {
                        var year = new Date(item.Year_Of_Release)
                        items.push({
                            Dataset_Name: item.Dataset_Name,
                            uploadDate: year.getFullYear(),
                            votes: item.votes,
                            ...item.columns
                        })
                        return null
                    })
                    this.setState({ items: items })
                    this.setState({ copyItems: items })
                    this.setState({ loadingDatasets: false });
                    this.searchResults();
                })
                .catch(err => console.log(err.response.data))

        } else {
            this.resetFilters();
            this.getDatasets()
        }

    }

    updateItems = (filteredItems, filters) => {
        this.setState({ items: filteredItems })
        this.setState({ checkedItems: filters })
    }

    searchResults = () => {
        this.setState({ loadingDatasets: true });
        if (this.state.searchValue) {
            let _this = this;
            let items = _.filter(this.state.items, function (dataset) {
                return _.includes(dataset['Dataset_Name'], _this.state.searchValue)
                    || _.includes(dataset[0].Study_purpose, _this.state.searchValue)
                    || _.includes(dataset[0].Data_collection_strategy, _this.state.searchValue)
                    || _.includes(dataset[0].Sub_category, _this.state.searchValue)
            })

            if (items.length > 0) {
                this.setState({ items: items })
                this.setState({ copyItems: items })
                setTimeout(() => this.setState({ loadingDatasets: false }), 500);
            }
            else {
                let items = []
                this.setState({ copyItems: items })
                this.setState({ items: items })
                if (this.state.items.length > 0) {
                    this.state.filteredDataFound = false;
                }
                setTimeout(() => this.setState({ loadingDatasets: false }), 500);
            }
        } else {
            this.getDatasets()
        }
    }

    splitItems = (items, strategy) => {
        let split = []
        items.forEach(singleItem => {
            if (singleItem[0].Strategy === strategy)
                split.push(singleItem)
        })
        return split
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    };

    resetFilters = () => {
        this.setState({ filtersUpdated: undefined });
    }

    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.searchResults()
        }
    }

    onSearchValueChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    resetSearchValue = () => {
        this.setState({ searchValue: '' });
    }

    render() {
        if (!this.state.loadingDatasets) {
            const { items } = this.state
            const { myDatasets } = this.state
            const DD = this.splitItems(items, "Download")
            const ED = this.splitItems(items, "Request")
            const CA = this.splitItems(items, "Contact")
            const { windowWidth } = this.state;
            if (windowWidth > 1200) {
                return (
                    <div>
                        {/* <DropdownFilters items={this.state.items} copyItems={this.state.copyItems} updateItems={this.updateItems} resetItems={this.resetItems} /> */}
                        <div className="container" style={{ marginTop: "54px" }}>
                            <div className="row" >
                                <div className="d-none d-lg-block col-lg-2 listheading" style={{ paddingBottom: '64px' }}>

                                    <Filter checkedItems={this.state.checkedItems} resetFilters={this.resetFilters} filtersUpdated={this.state.filtersUpdated} items={this.state.items} copyItems={this.state.copyItems} updateItems={this.updateItems} resetItems={this.resetItems} />

                                </div>
                                <div className="col-lg-10 col-sm-12">
                                    <div style={{ marginBottom: "24px" }}>
                                        <InputGroup>
                                            <FormControl type="text" placeholder="Search datasets" name="searchValue" value={this.state.searchValue} onKeyPress={this.onKeyPress} onChange={this.onSearchValueChange} aria-label="Search datasets" />
                                            <InputGroup.Append onClick={this.searchResults}>
                                                <InputGroup.Text role="button">
                                                    <img className="svgLabel pr-1" src={Search} alt="Search Datasets" />
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </div>
                                    <p className="m-0" aria-live="polite" aria-atomic="true">Total Items: {DD.length + ED.length + CA.length}</p>
                                    {(this.state.checkedItems.size > 0) ? (
                                        <div style={{ marginTop: "16px" }}>
                                            {[...this.state.checkedItems].map((key, value) => {
                                                if (key[1]) {
                                                    return <button className="round-button" role="button" onClick={() => this.removeFilter(key[0])} style={{ marginRight: "16px", marginBottom: "12px" }}>
                                                        <span className="pr-1">{key[0]}</span>
                                                        <img className="svgLabel pr-1" src={Close} role="button" alt="Remove Filter button" title="Remove Filter button" />
                                                    </button>
                                                }
                                            })}
                                            <span>
                                                <button className="round-button" role="button" onClick={() => this.resetItems()} style={{ marginRight: "16px", marginBottom: "12px" }}>
                                                    <span className="pr-1" style={{ textDecorationLine: "underline" }}>Clear Filters</span>
                                                </button>
                                            </span>
                                        </div>
                                    ) : ""}

                                    <div className="col-11" style={{ marginBottom: "16px" }}>
                                    </div>
                                    <Tabs className="tabs" defaultActiveKey="dd" id="uncontrolled-tab-example">
                                        <Tab eventKey="dd" title={"DOWNLOAD (" + DD.length + ")"}>
                                            <CardsGen id="dd" isMyData={false} items={DD} resetItems={this.resetItems} resetSearchValue={this.resetSearchValue} />
                                        </Tab>
                                        <Tab eventKey="ed" title={"REQUEST (" + ED.length + ")"}>
                                            <CardsGen id="ed" isMyData={false} items={ED} resetItems={this.resetItems} resetSearchValue={this.resetSearchValue} />
                                        </Tab>
                                        <Tab eventKey="ca" title={"CONTACT (" + CA.length + ")"}>
                                            <CardsGen id="ca" isMyData={false} items={CA} resetItems={this.resetItems} resetSearchValue={this.resetSearchValue} />
                                        </Tab>
                                        {(this.props.auth.isAuthenticated && this.props.auth.myDatasets !== undefined) ? (
                                            <Tab eventKey="myDset" title={"MY DATASETS (" + this.props.auth.myDatasets.length + ")"}>
                                                <CardsGen id="myDset" isMyData={true} items={this.props.auth.myDatasets} resetItems={this.resetItems} resetSearchValue={this.resetSearchValue} />
                                            </Tab>) : ""}
                                    </Tabs>
                                </div>
                            </div>

                        </div>
                    </div >
                )
            }
            else
                return (
                    <div>
                        <div className="container" style={{ paddingTop: '32px', paddingBottom: '8px', paddingLeft: "0px", paddingRight: "0px" }}>
                            <div className="row container" style={{ fontSize: "14px" }}>
                                <div className='col-9 mb-auto'>
                                    <InputGroup>
                                        <FormControl type="text" placeholder="Search datasets" name="searchValue" value={this.state.searchValue} onKeyPress={this.onKeyPress} onChange={this.onSearchValueChange} aria-label="Search datasets" />
                                        <InputGroup.Append onClick={this.searchResults}>
                                            <InputGroup.Text role="button">
                                                <img className="svgLabel pr-1" src={Search} alt="Search Datasets" />
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </div>
                                <div className="col-3">
                                    <DropdownFilters checkedItems={this.state.checkedItems} resetFilters={this.resetFilters} filtersUpdated={this.state.filtersUpdated} items={this.state.items} copyItems={this.state.copyItems} updateItems={this.updateItems} resetItems={this.resetItems} />
                                </div>
                            </div>
                        </div>
                        <div className="container" style={{ marginTop: "32px" }}>
                            <div className="row" style={{ marginBottom: "8px", paddingLeft: "16px", paddingRight: "16px" }}>
                                <p className="m-0">Total Items: {DD.length + ED.length + CA.length}</p>
                                <p className="m-0" aria-live="polite" aria-atomic="true" style={{ display: "none" }} style={{ display: 'none' }}>{DD.length + ED.length + CA.length} Datasets Found for your query</p>
                            </div>
                            <div className="row" style={{ marginBottom: "48px", paddingLeft: "16px", paddingRight: "16px" }}>
                                {(this.state.checkedItems.size > 0) ? (
                                    <div>
                                        {[...this.state.checkedItems].map((key, value) => {
                                            if (key[1]) {
                                                return <button className="round-button" role="button" onClick={() => this.removeFilter(key[0])} style={{ marginRight: "16px", marginTop: "16px" }}>
                                                    <span className="pr-1">{key[0]}</span>
                                                    <img className="svgLabel pr-1" src={Close} role="button" alt="Remove Filter button" title="Remove Filter button" />
                                                </button>
                                            }
                                        })}
                                        <span>
                                            <button className="round-button" role="button" onClick={() => this.resetItems()} style={{ marginRight: "16px", marginTop: "16px" }}>
                                                <span className="pr-1">Clear Filters</span>
                                            </button>

                                        </span>
                                    </div>
                                ) : ""}
                            </div>
                            <div className="row" >
                                <Tabs className="smalltabs" defaultActiveKey="dd" id="smalltab">
                                    <Tab eventKey="dd" title={"DOWNLOAD (" + DD.length + ")"}>
                                        <CardsGen id="dd" isMyData={false} items={DD} resetItems={this.resetItems} resetSearchValue={this.resetSearchValue} />
                                    </Tab>
                                    <Tab eventKey="ed" title={"REQUEST (" + ED.length + ")"}>
                                        <CardsGen id="ed" isMyData={false} items={ED} resetItems={this.resetItems} resetSearchValue={this.resetSearchValue} />
                                    </Tab>
                                    <Tab eventKey="ca" title={"CONTACT (" + CA.length + ")"}>
                                        <CardsGen id="ca" isMyData={false} items={CA} resetItems={this.resetItems} resetSearchValue={this.resetSearchValue} />
                                    </Tab>
                                    {(this.props.auth.isAuthenticated && this.props.auth.myDatasets !== undefined) ? (
                                        <Tab eventKey="myDset" title={"MY DATASETS (" + this.props.auth.myDatasets.length + ")"}>
                                            <CardsGen id="myDset" isMyData={true} items={this.props.auth.myDatasets} resetItems={this.resetItems} resetSearchValue={this.resetSearchValue} />
                                        </Tab>) : ""}
                                </Tabs>
                            </div>

                        </div>
                    </div>
                )
        }
        else if (this.state.loadingDatasets) {
            console.log("empty")
            const { windowWidth } = this.state;
            if (windowWidth > 1200) {
                return (

                    <div>

                        <div className="container" style={{ marginTop: "54px" }}>
                            <div className="row" >
                                <div className="d-none d-lg-block col-lg-2 listheading">

                                    <Filter items={this.state.items} copyItems={this.state.copyItems} updateItems={this.updateItems} resetItems={this.resetItems} />

                                </div>
                                <div className="col-lg-10 col-sm-12">
                                    <div style={{ marginBottom: "24px" }}>
                                        <InputGroup>
                                            <FormControl type="text" placeholder="Search datasets" name="searchValue" onKeyPress={this.onKeyPress} onChange={this.onSearchValueChange} aria-label="Search datasets" />
                                            <InputGroup.Append onClick={this.searchResults}>
                                                <InputGroup.Text>
                                                    <img className="svgLabel pr-1" src={Search} alt="Search Datasets" />
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </div>
                                    <p className="m-0">Total Items: 0</p>
                                    <div className="col-11" style={{ marginBottom: "16px" }}>
                                    </div>
                                    <Tabs className="tabs" defaultActiveKey="dd" id="uncontrolled-tab-example">
                                        <Tab eventKey="dd" title={"DOWNLOAD"}>
                                            <div className='sweet-loading'>
                                                <ClipLoader
                                                    sizeUnit={"px"}
                                                    size={40}
                                                    color={'#cccccc'}
                                                />
                                            </div>
                                        </Tab>
                                        <Tab eventKey="ed" title={"REQUEST"}>
                                            <div className='sweet-loading'>
                                                <ClipLoader
                                                    sizeUnit={"px"}
                                                    size={40}
                                                    color={'#cccccc'}
                                                />
                                            </div>
                                        </Tab>
                                        <Tab eventKey="ca" title={"CONTACT"}>
                                            <div className='sweet-loading'>
                                                <ClipLoader
                                                    sizeUnit={"px"}
                                                    size={40}
                                                    color={'#cccccc'}
                                                />
                                            </div>
                                        </Tab>
                                        {this.props.auth.isAuthenticated ? (
                                            <Tab eventKey="myDset" title={"MY DATASETS"}>
                                                <div className='sweet-loading'>
                                                    <ClipLoader
                                                        sizeUnit={"px"}
                                                        size={40}
                                                        color={'#cccccc'}
                                                    />
                                                </div>
                                            </Tab>
                                        ) : ""}
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div>
                        <div className="container" style={{ paddingTop: '32px', paddingBottom: '8px', paddingLeft: "0px", paddingRight: "0px" }}>
                            <div className="row container" style={{ fontSize: "14px" }}>
                                <div className='col-9 mb-auto'>
                                    <InputGroup>
                                        <FormControl type="text" placeholder="Search datasets" name="searchValue" onKeyPress={this.onKeyPress} onChange={this.onSearchValueChange} aria-label="Search datasets" />
                                        <InputGroup.Append onClick={this.searchResults}>
                                            <InputGroup.Text>
                                                <img className="svgLabel pr-1" src={Search} alt="Search Datasets" />
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </div>
                                <div className="col-3">
                                    <DropdownFilters checkedItems={this.state.checkedItems} resetFilters={this.resetFilters} filtersUpdated={this.state.filtersUpdated} items={this.state.items} copyItems={this.state.copyItems} updateItems={this.updateItems} resetItems={this.resetItems} />
                                </div>
                            </div>
                        </div>


                        <div className="container" style={{ marginTop: "32px" }}>
                            <div className="row" style={{ marginBottom: "8px", paddingLeft: "16px", paddingRight: "16px" }}>
                                <p className="m-0">Total Items: 0</p>
                            </div>

                        </div>
                        <Tabs className="tabs" defaultActiveKey="dd" id="uncontrolled-tab-example">
                            <Tab eventKey="dd" title={"DOWNLOAD"}>
                                <div className='sweet-loading'>
                                    <ClipLoader
                                        sizeUnit={"px"}
                                        size={40}
                                        color={'#cccccc'}
                                    />
                                </div>
                            </Tab>
                            <Tab eventKey="ed" title={"REQUEST"}>
                                <div className='sweet-loading'>
                                    <ClipLoader
                                        sizeUnit={"px"}
                                        size={40}
                                        color={'#cccccc'}
                                    />
                                </div>
                            </Tab>
                            <Tab eventKey="ca" title={"CONTACT"}>
                                <div className='sweet-loading'>
                                    <ClipLoader
                                        sizeUnit={"px"}
                                        size={40}
                                        color={'#cccccc'}
                                    />
                                </div>
                            </Tab>
                            {this.props.auth.isAuthenticated ? (
                                <Tab eventKey="myDset" title={"MY DATASETS"}>
                                    <div className='sweet-loading'>
                                        <ClipLoader
                                            sizeUnit={"px"}
                                            size={40}
                                            color={'#cccccc'}
                                        />
                                    </div>
                                </Tab>
                            ) : ""}
                        </Tabs>
                    </div>)
            }
        }
    }
}
const mapStatesToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
})
export default connect(mapStatesToProps, { myDatasets })(withRouter(Datasets))