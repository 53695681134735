import React, {Component} from 'react'
import CheckboxGroup from '../common/CheckboxGroup'
import TextFieldGroup from '../common/TextFieldGroup'
import DropdownFieldGroup from '../common/DropdownFieldGroup'

import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {addDataset, loginGoogleUser} from '../../actions/authActions'
import { GoogleLogin } from 'react-google-login';
import ImageUploader from 'react-images-upload';
import { Modal, Button } from "react-bootstrap";
import { DH_NOT_SUITABLE_GENERATOR } from 'constants'

class AddDataset extends Component{
    constructor(){
        super()
        this.state = {
            isOpen : false,
            Dataset_Name:"",
            Link_Paper:"",
            Link_Dataset:"",
            Study_purpose:"",
            Data_collection_strategy:"",

            Autism:"0",
            Cognitive:"0",
            Developmental:"0",
            Health:"0",
            Hearing:"0",
            Language:"0",
            Learning:"0",
            Mobility:"0",
            Speech:"0",
            Vision:"0",

            Audio:"0",
            Video:"0",
            Text:"0",
            Motion:"0",
            Image:"0",
            Logs:"0",
            Sensing:"0",

            Sub_category:"",
            No_of_Participants:"",
            Authors:"",
            Contact_Emails:"",
            Strategy:"",
            errors:{},
            datatypes:['Audio', 'Images', 'Logs', 'Motion', 'Sensing', 'Text', 'Video'],
            categories:["Autism", "Cognitive", "Developmental", "Health", "Hearing", "Language", "Learning", "Mobility", "Speech", "Vision"],
            checkedItems: new Map(),
            pictures: []
        }
        this.onDrop = this.onDrop.bind(this);
        this.handleCheckboxListChange = this.handleCheckboxListChange.bind(this);
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    onDrop(picture) {
        this.setState({
            pictures: this.state.pictures.concat(picture),
        });
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.errors){
          this.setState({errors: nextProps.errors})
        }
      }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }
    responseGoogle = (e) => {
        this.props.loginGoogleUser(e, this.props.history)
      }

    onSubmit = e => {
        e.preventDefault();
        const {user} = this.props.auth
        console.log(user)
        const userData = {
            user: user.googleId,
            Dataset_Name:this.state.Dataset_Name,
            Link_Paper:this.state.Link_Paper,
            Link_Dataset:this.state.Link_Dataset,
            Study_purpose:this.state.Study_purpose,
            Data_collection_strategy:this.state.Data_collection_strategy,
            
            Autism:this.state.Autism,
            Cognitive:this.state.Cognitive,
            Developmental:this.state.Developmental,
            Health:this.state.Health,
            Hearing:this.state.Hearing,
            Language:this.state.Language,
            Learning:this.state.Learning,
            Mobility:this.state.Mobility,
            Speech:this.state.Speech,
            Vision:this.state.Vision,

            Sub_category: this.state.Sub_category,

            checkedItems: this.state.checkedItems,

            Audio:this.state.Audio,
            Video:this.state.Video,
            Text:this.state.Text,
            Motion:this.state.Motion,
            Image:this.state.Image,
            Logs:this.state.Logs,
            Sensing:this.state.Sensing,
            
            No_of_Participants:this.state.No_of_Participants,
            Authors:this.state.Authors,
            Contact_Emails:this.state.Contact_Emails,
            Strategy:this.state.Strategy,

        }
        console.log(userData)
        this.props.addDataset(userData, this.props.history)
      }

      handleCheckboxListChange = (e) => {
        // values is array of selected item. e.g. ['apple', 'banana']
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));

        if (isChecked){
            switch (item) {
                case "Audio":
                    console.log(item, isChecked)
                    this.setState({Audio: "1"})
                    break;
                case "Video":
                    this.setState({Video: "1"})
                    break;
                case "Text":
                    this.setState({Text: "1"})
                    break;
                case "Motion":
                    this.setState({Motion: "1"})
                    break;
                case "Image":
                    this.setState({Image: "1"})
                    break;
                case "Logs":
                    this.setState({Logs: "1"})
                    break;
                case "Sensing":
                    this.setState({Sensing: "1"})
                    break;
            }
        }
        else {
            switch (item) {
                case "Audio":
                    console.log(item, isChecked)
                    this.setState({Audio: "0"})
                    break;
                case "Video":
                    this.setState({Video: "0"})
                    break;
                case "Text":
                    this.setState({Text: "0"})
                    break;
                case "Motion":
                    this.setState({Motion: "0"})
                    break;
                case "Image":
                    this.setState({Image: "0"})
                    break;
                case "Logs":
                    this.setState({Logs: "0"})
                    break;
                case "Sensing":
                    this.setState({Sensing: "0"})
                    break;
            }
        }
            console.log("changed +",this.state, item, isChecked)
    }

    handleCheckboxCategoriesChange = (e) => {
        // values is array of selected item. e.g. ['apple', 'banana']
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
        console.log(item, isChecked)
        if (isChecked){
            switch (item) {
                case "Autism":
                    console.log(item, isChecked)
                    this.setState({Autism: "1"})
                    break;
                case "Cognitive":
                    this.setState({Cognitive: "1"})
                    break;
                case "Developmental":
                    this.setState({Developmental: "1"})
                    break;
                case "Health":
                    this.setState({Health: "1"})
                    break;
                case "Hearing":
                    this.setState({Hearing: "1"})
                    break;
                case "Language":
                    this.setState({Language: "1"})
                    break;
                case "Learning":
                    this.setState({Learning: "1"})
                    break;
                case "Mobility":
                    this.setState({Mobility: "1"})
                    break;
                case "Speech":
                    this.setState({Speech: "1"})
                    break;
                case "Vision":
                    this.setState({Vision: "1"})
                    break;
            }
        }
        else {
            switch (item) {
                case "Autism":
                    this.setState({Autism: "0"})
                    break;
                case "Cognitive":
                    this.setState({Cognitive: "0"})
                    break;
                case "Developmental":
                    this.setState({Developmental: "0"})
                    break;
                case "Health":
                    this.setState({Health: "0"})
                    break;
                case "Hearing":
                    this.setState({Hearing: "0"})
                    break;
                case "Language":
                    this.setState({Language: "0"})
                    break;
                case "Learning":
                    this.setState({Learning: "0"})
                    break;
                case "Mobility":
                    this.setState({Mobility: "0"})
                    break;
                case "Speech":
                    this.setState({Speech: "0"})
                    break;
                case "Vision":
                    this.setState({Vision: "0"})
                    break;
            }
        }
            console.log("changed +",this.state, item, isChecked)
    }

    handleCheckboxDatatypesChange = (e) => {
        // values is array of selected item. e.g. ['apple', 'banana']
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));

        if (e.target.checked){
            this.setState({ item: isChecked });
            console.log("changed +",this.state, item, isChecked)
        }
    }

    renderTooltip = (x) => (
        <Tooltip title={x} aria-live="polite" className="tooltip-right">
            {x}
        </Tooltip>
    );

    render(){
        const { errors } = this.state;
        const {isAuthenticated} = this.props.auth
        if(isAuthenticated)
            return (
            <div className="container">
                <div className="p-2 text-center">
                    <h5>Submit a request form to link a dataset</h5>
                    <p>Fields marked with an asterisk * are required.</p>
                </div>

                <form onSubmit={this.onSubmit}>
                    <div>
                        <div className="row">
                            <div className="col-2 pb-2">
                                    <ImageUploader
                                        withIcon={true}
                                        singleImage={true}
                                        withPreview={true}
                                        buttonText='Add image'
                                        onChange={this.onDrop}
                                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                        maxFileSize={5242880}
                                        />
                                {/* <div className="  squareSmall mt-auto" style={{borderColor:"#000000"}} >
                                    <div className="cont">
                                        <div className="table">
                                            <input type="file" name="pic" accept="image/*"></input>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            
                            <div className="col-10">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex">
                                            <label htmlFor="nameInput">Name of the dataset*</label>
                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{ show: 0, hide: 200 }}
                                                    overlay={this.renderTooltip("If the dataset has a name, enter it or enter the paper title.")}
                                                    trigger={["hover","focus"]}
                                                >
                                                <span role="tooltip" aria-label="Focus for info" className="mx-2 dot text-center"><p aria-hidden="true">?</p></span>
                                                </OverlayTrigger>
                                        </div>
                                        <TextFieldGroup
                                        placeholder="Enter dataset name"
                                        name="Dataset_Name"
                                        type="text"
                                        id="nameInput"
                                        aria-required="true"
                                        value={this.state.Dataset_Name}
                                        onChange={this.onChange}
                                        error={errors.Dataset_Name}
                                        />
                                    </div>
                                </div>
                                            
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex ">
                                            <label htmlFor="authorInput" className=" ">Authors*</label>
                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{ show: 0, hide: 200 }}
                                                    overlay={this.renderTooltip("Enter the names of all authors, separated with a comma. Names should be in the order: Firstname Lastname (your family name) or Firstname Middlename Lastname. Do not include honorifics e.g. Dr., Professor.")}
                                                    trigger={["hover","focus"]}
                                                >
                                                    <span role="tooltip" aria-label="Focus for info" className="mx-2 dot text-center"><p aria-hidden="true">?</p></span>
                                                </OverlayTrigger>
                                        </div>
                                        <TextFieldGroup
                                        placeholder="Enter full name of the author(s)"
                                        name="Authors"
                                        type="text"
                                        id="authorInput"
                                        aria-required="true"
                                        value={this.state.Authors}
                                        onChange={this.onChange}
                                        error={errors.Authors}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex">
                                            <label htmlFor="descriptionInput">Description*</label>
                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{ show: 0, hide: 200 }}
                                                    overlay={this.renderTooltip("Describe the purpose and method of data collection. Keep it short - limited to 500 characters.")}
                                                >
                                                    <span role="tooltip" aria-label="Focus for info" className="mx-2 dot text-center"><p aria-hidden="true">?</p></span>
                                                </OverlayTrigger>
                                        </div>
                                        <TextFieldGroup
                                        placeholder="Briefly describe the dataset in 2 to 3 sentences"
                                        name="Study_purpose"
                                        type="text"
                                        id="descriptionInput"
                                        aria-required="true"
                                        value={this.state.Study_purpose}
                                        onChange={this.onChange}
                                        error={errors.Study_purpose}
                                        />
                                    </div>
                                </div>

                            </div>
                            
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <div className="d-flex">
                                <label id="selectCommunities">Communities of focus*</label>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 0, hide: 200 }}
                                        overlay={this.renderTooltip("Select communities or populations represented in the data. For details, refer to our latest paper found on About.")}
                                    >
                                        <span role="tooltip" aria-label="Focus for info" className="mx-2 dot text-center"><p aria-hidden="true">?</p></span>
                                    </OverlayTrigger>
                            </div>

                            <CheckboxGroup
                            items = {this.state.categories}
                            labelledby="selectCommunities"
                            error={errors.CategoryList}
                            checkeditems={this.state.checkedItems}
                            onChange={this.handleCheckboxCategoriesChange}
                            /> 
                        </div>

                        <div className="col-6">
                            <div className="d-flex">
                                <label id="selectData">Data types*</label>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 0, hide: 200 }}
                                        overlay={this.renderTooltip("Select the types of data collected. For details, refer to our latest paper found on About.")}
                                    >
                                            <span role="tooltip" aria-label="Focus for info" className="mx-2 dot text-center"><p aria-hidden="true">?</p></span>
                                    </OverlayTrigger>
                            </div>
                            <CheckboxGroup
                            items = {this.state.datatypes}
                            labelledby="selectData"
                            error={errors.DatatypeList}
                            checkeditems={this.state.checkedItems}
                            onChange={this.handleCheckboxListChange}
                            />
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-2">
                            <div className="d-flex">
                                <label htmlFor="accessDrop">How to access?*</label>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 0, hide: 200 }}
                                        overlay={this.renderTooltip("Select how the dataset can be accessed. In Download, datasets are publicly available and can be downloaded directly. Datasets available upon Request can be accessed through specific procedures set by the dataset creators. Those under Contact don't include any sharing intent or information.")}
                                    >
                                        <span role="tooltip" aria-label="Focus for info" className="mx-1 dot text-center"><p aria-hidden="true">?</p></span>
                                    </OverlayTrigger>
                            </div>
                            <DropdownFieldGroup
                            options={["Download", "Request", "Contact"]}
                            placeholder="How to access the dataset?"
                            name="Strategy"
                            id="accessDrop"
                            type="emails"
                            value={this.state.Strategy}
                            onChange={this.onChange}
                            error={errors.Strategy}
                            />
                        </div>

                        <div className="col-2">
                            <div className="d-flex">
                                <label htmlFor="sizeInput">Participant size*</label>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 0, hide: 200 }}
                                        overlay={this.renderTooltip("Enter the total number of people within the communities of focus contributed to the dataset.")}
                                    >
                                        <span role="tooltip" aria-label="Focus for info" className="mx-1 dot text-center"><p aria-hidden="true">?</p></span>
                                    </OverlayTrigger>
                            </div>
                            <TextFieldGroup
                            placeholder="Number of Participants"
                            name="No_of_Participants"
                            type="text"
                            id="sizeInput"
                            aria-required="true"
                            value={this.state.No_of_Participants}
                            onChange={this.onChange}
                            error={errors.No_of_Participants}
                            />
                        </div>

                        <div className="col-2">
                            <div className="d-flex">
                                <span className="mr-auto">Link*</span>
                                <label htmlFor="linkDataInput">to dataset</label>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 0, hide: 200 }}
                                        overlay={this.renderTooltip("Enter where the dataset can be accessed or where people can find more about the dataset access e.g. a link to the host site or the project page.")}
                                    >
                                        <span role="tooltip" aria-label="Focus for info" className="mx-1 dot text-center"><p aria-hidden="true">?</p></span>
                                    </OverlayTrigger>
                            </div>
                            <TextFieldGroup
                            placeholder="Link to the dataset"
                            name="Link_Dataset"
                            type="text"
                            id="linkDataInput"
                            value={this.state.Link_Dataset}
                            onChange={this.onChange}
                            error={errors.Link_Dataset}
                            />
                        </div>

                        <div className="col-2">
                            <div className="d-flex">
                                <label htmlFor="linkPaperInput">to paper</label>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 0, hide: 200 }}
                                        overlay={this.renderTooltip("Enter a link, if any, to a publicaiton related to the dataset.")}
                                    >
                                        <span role="tooltip" aria-label="Focus for info" className="mx-1 dot text-center"><p aria-hidden="true">?</p></span>
                                    </OverlayTrigger>
                            </div>
                            <TextFieldGroup
                            placeholder="Link to the paper"
                            name="Link_Paper"
                            type="text"
                            id="linkPaperInput"
                            value={this.state.Link_Paper}
                            onChange={this.onChange}
                            error={errors.Link_Paper}
                            />
                        </div>                        

                        <div className="col-4">
                            <div className="d-flex">
                                <label htmlFor="contactInput">Contact*</label>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 0, hide: 200 }}
                                        overlay={this.renderTooltip("Provide a single primary point of contact email for all correspondence about the dataset.")}
                                    >
                                        <span role="tooltip" aria-label="Focus for info" className="mx-1 dot text-center"><p aria-hidden="true">?</p></span>
                                    </OverlayTrigger>
                            </div>
                            <TextFieldGroup
                            placeholder="abc@example.com"
                            name="Contact_Emails"
                            type="emails"
                            id="contactInput"
                            value={this.state.Contact_Emails}
                            onChange={this.onChange}
                            error={errors.Contact_Emails}
                            />
                        </div>
                    </div>
                    <div className="row">

                    <div className="col-12">
                            <div className="d-flex">
                                <label htmlFor="tagsInput">Tags*</label>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 0, hide: 200 }}
                                        overlay={this.renderTooltip("Enter up to 5 keywords or sub-categories that describe the work.")}
                                    >
                                        <span role="tooltip" aria-label="Focus for info" className="mx-1 dot text-center"><p aria-hidden="true">?</p></span>
                                    </OverlayTrigger>
                            </div>
                            <TextFieldGroup
                            placeholder="What other keywords describe the work?"
                            name="Sub_category"
                            type="text"
                            id="tagsInput"
                            value={this.state.Sub_category}
                            onChange={this.onChange}
                            error={errors.Sub_category}
                            />
                        </div>
                    </div>

                    <div className="text-center">

                    <input type="submit" className="btn btncolor btn-info mt-4" value="Submit dataset" />
                    </div>
                </form>
                        <Modal show={this.state.isOpen} onHide={this.closeModal}>
        <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
            Close
            </Button>
        </Modal.Footer>
        </Modal>
            </div>
            )
        else
            return (
                <div className="container">
                    <div className="row justify-content-md-center">
                    <GoogleLogin
                        clientId="915080396743-6nhp55re256cvhl9jah28lncndibal16.apps.googleusercontent.com"
                        buttonText="Login With google to add datasets"
                        onSuccess={this.responseGoogle}
                        cookiePolicy={'single_host_origin'}
                    /></div>
                </div>)
    }
}
const mapStatesToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
  })

export default connect(mapStatesToProps, {addDataset, loginGoogleUser})(withRouter(AddDataset))