import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const TextFieldGroup = ({
  name,
  placeholder,
  value,
  options,
  label,
  id,
  error,
  info,
  type,
  onChange,
  disabled
}) => {
    console.log(options)
    let generatedOptions = [<option key="0" value="" defaultValue>Choose here</option>]
    options.forEach(element => {
        
        generatedOptions.push(<option key={element} value={element}>{element}</option>);
    })
  return (
    <div className="form-group">
      <select
      type={type}
      className={classnames('form-control form-control-lg', {
        'is-invalid': error
      })}
      style={{fontSize:"12px", height:"32px"}}
      placeholder={placeholder}
      name={name}
      id={id}
      value={value}
      onChange={onChange}
      disabled={disabled}
      >
        {generatedOptions}
      </select>
      {info && <small className="form-text text-muted">{info}</small>}
      {error && <div className="invalid-feedback" aria-live="polite" role="alert">{error}</div>}
    </div>
  );
};

TextFieldGroup.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.string
};

TextFieldGroup.defaultProps = {
  type: 'text'
};

export default TextFieldGroup;