import React from 'react'

export default ()=>{
    return (

                <div className="text-white mt-2 p-2 text-center footer fixed-bottom">
                    Copyright &copy; {new Date().getFullYear()} IAM Lab
                </div>
            // <div className="text-white p-2 text-center fixed-bottom footer position-sticky">
            //     Copyright &copy; {new Date().getFullYear()} IAM Lab
            // </div>
        //     <div className="fixed-bottom">  
        //     <Navbar color="dark" dark>
        //         <Container>
        //             <NavbarBrand>Footer</NavbarBrand>
        //         </Container>
        //     </Navbar>
        // </div>
    )
}