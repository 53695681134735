import React, { Component } from 'react'
import shortnames from 'short-name'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { editDataset, updateDataset, myDatasets, voteDataset } from '../../actions/authActions'

import { Event, initGA } from '../tracking/GoogleAnalytics'
import { Helmet } from "react-helmet";

// import {ReactComponent as Audio} from "../../images/Audio.svg"
// import {ReactComponent as Video} from "../../images/Video.svg"
// import {ReactComponent as TextSvg} from "../../images/Text.svg"
// import {ReactComponent as Motion} from "../../images/Motion.svg"
// import Motion from "../../images/Motion.png"
// import {ReactComponent as ImageSvg} from "../../images/Image.svg"
// import {ReactComponent as Logs} from "../../images/Logs.svg"
// import {ReactComponent as Statistic} from "../../images/Statistic.svg"
// import {ReactComponent as Sensing} from "../../images/Sensing.svg"
import People from '../../images/People.png'
import Category from "../../images/Category.svg"
import Calendar from "../../images/Calendar.svg"
import Datatype from "../../images/Datatype.svg"
import Tick from "../../images/Tick.svg"
import Question from "../../images/Question.svg"
import Stop from "../../images/Stop.svg"
import Paper from "../../images/Document.svg"
import Contact from "../../images/Contact.svg"
import Dataset from "../../images/Dataset.svg"
import axios from 'axios'

// import People from "../../images/People.svg"

// import Datatype from "../../images/Datatype.svg"

// import Calendar from "../../images/Calendar.png"
import Autism from "../../images/categories/AUTISM.png"
import Cognitive from "../../images/categories/COGNITIVE.png"
import Developmental from "../../images/categories/DEVELOPMENTAL.png"
import Health from "../../images/categories/HEALTH.png"
import Hearing from "../../images/categories/HEARING.png"
import Language from "../../images/categories/LANGUAGE.png"
import Learning from "../../images/categories/LEARNING.png"
import Mobility from "../../images/categories/MOBILITY.png"
import Speech from "../../images/categories/SPEECH.png"
import Vision from "../../images/categories/VISION.png"
import CategoryImg from './CategoryImg'

class DatasetCard extends Component {
    _isMounted = false;
    constructor() {
        super()
        this.state = {
            item: {},
            expanded: false,
            // upvotes: Math.ceil(Math.random()*100),
            votes: 0,
            accepted: "",
            published: "",
            newItem: {},
            errors: {}
        }
        this.editPressed = this.editPressed.bind(this);
    }

    // getIconMap(item){
    //     const typeToIconMap=['Audio', 'Images', 'Logs', 'Motion', 'Sensing', 'Text', 'Video']
    //     let vals = values.split(',').map(Number)
    //     const icons = {
    //         'Audio':0,
    //         'Images':0,
    //         'Logs':0,
    //         'Motion':0,
    //         'Sensing':0,
    //         'Text':0,
    //         'Video':0
    //     }

    //     vals.forEach((type, idx)=>{
    //         if(type===1){
    //             icons[typeToIconMap[idx]] = 1
    //         }
    //     })
    //     // remove when new text image is added
    //     icons["Text"]=0
    //     return icons
    // }

    // getTypeMap(values){
    //     const typeToIconMap=['Audio', 'Images', 'Logs', 'Motion', 'Sensing', 'Text', 'Video']
    //     let vals = values.split(',').map(Number)
    //     let types = []
    //     vals.forEach((type, idx)=>{
    //         if(type===1){
    //             if(typeToIconMap[idx] !== undefined)
    //                 types.push(typeToIconMap[idx])
    //         }
    //     })

    //     return types.join(', ')
    // }

    getTypeMap(item) {
        const datatypes = ['Audio', 'Image', 'Logs', 'Motion', 'Sensing', 'Text', 'Video']
        let types = []
        datatypes.forEach((type, idx) => {
            if (item[type] === "1") {
                types.push(type)
            }
        })
        return types.join(', ')
    }

    getTypeMapList(item) {
        const datatypes = ['Audio', 'Image', 'Logs', 'Motion', 'Sensing', 'Text', 'Video']
        let types = []
        datatypes.forEach((type, idx) => {
            if (item[type] === "1") {
                types.push(type)
            }
        })

        return types
    }
    componentDidMount() {
        this._isMounted = true;
        this.setState({ item: this.props.item })
        this.setState({
            accepted: this.props.item.accepted,
            published: this.props.item.published
        })
        initGA('UA-171548325-1')
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors })
        }
        if (nextProps.items) {
            this.setState({ newItem: nextProps.item })
            this.setState({
                accepted: nextProps.item.accepted,
                published: nextProps.item.published
            })
        }
    }

    expand = () => {
        this.setState({ expanded: !this.state.expanded });
    }

    collapse = () => {
        this.setState({ expanded: false });
    }

    getCategories = item => {
        const categories = ["Autism", "Cognitive", "Developmental", "Language", "Learning", "Health", "Hearing", "Mobility", "Speech", "Vision"]
        let itemCategories = []
        categories.forEach((category) => {
            if (item[category] === "1")
                itemCategories.push(category)
        })
        return itemCategories
    }

    getCategoriesStr = item => {
        const categories = ["Autism", "Cognitive", "Developmental", "Language", "Learning", "Health", "Hearing", "Mobility", "Speech", "Vision"]
        let itemCategories = []
        categories.forEach((category) => {
            if (item[category] === "1")
                itemCategories.push(category)
        })
        return itemCategories.join(", ")
    }

    categoryImage = category => {
        const imageMap = {
            "Autism": Autism,
            "Cognitive": Cognitive,
            "Developmental": Developmental,
            "Language": Language,
            "Learning": Learning,
            "Health": Health,
            "Hearing": Hearing,
            "Mobility": Mobility,
            "Speech": Speech,
            "Vision": Vision,
        }
        return imageMap[category]
    }
    update = () => {
        // voting on a dataset is only allowed when the user is logged in
        if (this.props.auth.user.email !== undefined && this.props.auth.isAuthenticated) {
            let datasetName = this.state.item.Dataset_Name;
            let userName = this.props.auth.user.email;
            axios
                .post("/api/datasets/voteDataset", { "Dataset_Name": datasetName, "username": userName })
                .then(res => {
                    // updating the item variable to reflect the changes in votes
                    let newItem = res.data;
                    var year = new Date(newItem.Year_Of_Release)
                    let item = {
                        published: newItem.published,
                        accepted: newItem.accepted,
                        Dataset_Name: newItem.Dataset_Name,
                        uploadDate: year.getFullYear(),
                        votes: newItem.votes,
                        ...newItem.columns,
                        votedBy: newItem.votedBy
                    }
                    this.setState({ item: item });
                })
        }
    }

    editPressed = () => {
        this.props.onClicked(this.state.item.Dataset_Name)
    }

    switchAccept = (key, currVal) => {
        let newVal;
        const { isGod } = this.props.auth
        console.log(this.state.accepted, this.state.published)
        if (isGod) {
            if (this.state.accepted === "A") {
                newVal = "R"
            }
            else
                newVal = "A"
            this.props.updateDataset(this.state.item.Dataset_Name, "accepted", newVal)
                .then(() => {
                    if (this.state.accepted === "A" && this.state.published === "Y")
                        this.switchPublish();
                    else
                        this.props.myDatasets(this.props.auth.user.email)
                })
            this.setState({
                accepted: newVal
            })
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    switchPublish = (key, currVal) => {
        let newVal;
        if (this.state.published === "Y")
            newVal = "N"
        else if (this.state.accepted === "A")
            newVal = "Y"
        this.props.updateDataset(this.state.item.Dataset_Name, "published", newVal)
            .then(() => {
                this.props.myDatasets(this.props.auth.user.email)
            })
        this.setState({
            published: newVal
        })
    }

    strip = str => {
        str = str.replace("and", "")
        str = str.replace(/^\s+|\s+$/g, '');
        return str
    }

    shortNames = authors => {
        if (authors === undefined)
            return ""
        authors = authors.replace("&", ",")
        let newnames = []
        authors.split(",").forEach((author) => {
            if (!author.includes(".")) {
                author = author.split(" ").reverse().join(" ")
                author = this.strip(author)
                // console.log(author)

                if (author.length > 1) {
                    author = shortnames(author)
                    author = author.split(" ").reverse().join(" ")
                }
            }
            newnames.push(author)
        })
        // newnames = newnames.slice(0,3)

        return newnames.join(", ")
    }


    getCreators = authors => {
        if (authors === undefined)
            return ""
        authors = authors.replace("&", ",")
        let newnames = []
        let person = {}
        authors.split(",").forEach((author) => {
            person = {
                "@type": "Person",
                "name": author
            }
            newnames.push(person)
        })
        // newnames = newnames.slice(0,3)
        return newnames
    }

    render() {
        const { item, upvotes } = this.state
        const { isGod, isAuthenticated } = this.props.auth
        const { isMyData } = this.props
        let exposeToGoogle = true

        if (item[0]) {
            exposeToGoogle = (item[0].Strategy !== "Contact")
            const contact = (<div className="px-1"><a className="button" onClick={() => { Event("CONTACT", "Contact link clicked", item.Dataset_Name) }} target="_blank" rel="noopener noreferrer" href={`mailto: ${item[0].Contact_Emails}`} style={{ color: "#000000", fontSize: "12px" }}>
                <img className="svgLabel pr-1" src={Contact} alt="Email for" title="Email for" />
                <span>Contact</span></a></div>)
            const paper = (<div className="px-1"><a className='button' onClick={() => { Event("PAPER", "Publication link clicked", item.Dataset_Name) }} target="_blank" rel="noopener noreferrer" href={item[0].Link_Paper} style={{ color: "#000000", fontSize: "12px" }}>
                <img className="svgLabel pr-1" src={Paper} alt="Link to" title="Link to paper" />
                <span>Paper</span></a></div>)
            const dataset = (<div className="px-1"><a className="button" onClick={() => { Event("DATASETLINK", "Dataset link clicked", item.Dataset_Name) }} target="_blank" rel="noopener noreferrer" href={item[0].Link_Dataset} style={{ color: "#000000", fontSize: "12px" }}>
                <img className="svgLabel pr-1" src={Dataset} alt="Link to" title="Link to dataset" />
                <span>Dataset</span></a></div>)
            const link = (item[0].Link_Dataset === "") ? "" : item[0].Link_Dataset
            const ldJson = {
                "@context": "https://schema.org",
                "@type": "Dataset",
                "creator": this.getCreators(item[0].Authors),
                "name": item.Dataset_Name,
                "datePublished": item.uploadDate,
                "distribution": link,
                "description": item[0].Study_purpose,
                "measurementTechnique": item[0].Data_collection_strategy,
                "accessMode": this.getTypeMapList(item[0])
            };

            const helmet = (
                <Helmet>
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </Helmet>
            )

            return (
                <div className="container item" >

                    {(exposeToGoogle) ? helmet : ""}
                    <div className="container">
                        {/* <div className="row pb-1"> */}
                        <div className="row">

                            <div className="col-2 pb-2" onClick={this.expand}>
                                {/* <span>
                                    <img className="align-middle" src={this.categoryImage(item[0].Category)} alt={`Category type ${item[0].Category}`} title={`Category type ${item[0].Category}`} />
                                </span> */}
                                {/* <CategoryImg category={item[0].Category}/> */}
                                <CategoryImg category={this.getCategories(item[0])} />
                            </div>
                            <div className="col-8 flex-column d-flex align-items-start" style={{ maxHeight: "200px" }}>
                                <h6 className="row" style={{ fontWeight: "600", marginBottom: "0px" }}>{item.Dataset_Name}</h6>
                                <p className="row mt-0 " style={{ fontSize: "12px" }}>{this.shortNames(item[0].Authors)}</p>
                                <p className="row mt-1 " style={{ fontSize: "12px" }}>{item[0].Study_purpose + " " + item[0].Data_collection_strategy}</p>
                                <div className="row mt-auto d-flex justify-content-between">
                                    {/* <div className="col-2 "> <img src={Calendar} style={{width:"24%"}} alt="Year of upload icon" title="Year of upload icon" /> <span style={{fontSize:"12px", alignContent:"center"}}>2019</span></div>                                     */}
                                    {/* <div className="col-2"> <img className="svgLabel" alt="Number of participants" title="Number of participants" src={People}/> <span style={{fontSize:"12px", alignContent:"center"}}>{item[0].Category}</span>  </div>                              */}
                                    {/* <div className="col-3 "> <img src={People} style={{width:"10%"}} alt={`Category ${item[0].Category}`} title={`Category ${item[0].Category}`} /> <span style={{fontSize:"12px"}}>{item[0].Category}</span></div> */}
                                    {/* <div className="col-5 "> <img src={People} style={{width:"9%"}} alt={`Datatypes ${this.getTypeMap(item[0].Data_types)}`} title={`Datatypes ${this.getTypeMap(item[0].Data_types)}`} /> <span style={{fontSize:"12px"}}>{this.getTypeMap(item[0].Data_types)}</span></div>                                                                     */}
                                    <div className="col-2 px-0"> <img className="svgLabel" alt="Year of upload icon" title="Year of upload icon" src={Calendar} /> <span style={{ fontSize: "12px", alignContent: "center" }}>{item.uploadDate}</span>  </div>
                                    <div className="col-2 px-0"> <img src={People} style={{ width: "24%" }} alt="Number of participants" title="Number of participants" /> <span style={{ fontSize: "12px" }}>{item[0].No_of_Participants==-1 ? 'N/A' : item[0].No_of_Participants}</span></div>
                                    {/* <div className="col-3 px-0"> <img className="svgLabel" alt={`Category ${item[0].Category}`} title={`Category ${item[0].Category}`} src={Category}/> <span style={{fontSize:"12px", alignContent:"center"}}>{item[0].Category}</span>  </div>                              */}
                                    <div className="col-3 px-0"> <img className="svgLabel" alt={`Category ${this.getCategoriesStr(item[0])}`} title={`Category ${this.getCategoriesStr(item[0])}`} src={Category} /> <span style={{ fontSize: "12px", alignContent: "center" }}>{this.getCategoriesStr(item[0])}</span>  </div>
                                    {/* <div className="col-3 px-0"> <img className="svgLabel" alt="Category" title="Category" src={Category}/> <span style={{fontSize:"12px", alignContent:"center"}}>{item[0].Category}</span>  </div>                              */}
                                    <div className="col-5 px-0"> <img className="svgLabel" src={Datatype} alt={`Datatypes ${this.getTypeMap(item[0])}`} title={`Datatypes ${this.getTypeMap(item[0])}`} /> <span style={{ fontSize: "12px" }}>{this.getTypeMap(item[0])}</span></div>
                                </div>
                            </div>
                            <div className="col-2 px-0" style={{ margin: "0 auto" }}>
                                <div className={(!isMyData) ? "px-4 pt-0" : "hidden"} role="group" alt="upvote button and number of upvotes">
                                    <button type="button" className="button arrow" onClick={this.update} aria-label="upvote button" >
                                        <svg style={{ width: "10px" }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-up" className="svg-inline--fa fa-angle-up fa-w-10 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                            <path fill="currentColor" d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z">
                                            </path>
                                        </svg>
                                    </button>
                                    <span type="button" className="button number">{item.votes}</span>
                                </div>
                                <div className={(isGod || isMyData) ? "px-4 pt-1" : "hidden"} role="group" aria-label="edit this dataset">
                                    <button className="button p-1" onClick={this.editPressed}>
                                        <span>Edit</span>
                                    </button>
                                </div>
                                <div className={((isAuthenticated && isMyData) || isGod) ? "px-4 pt-1" : "hidden"} role="group" aria-label="edit this dataset">
                                    <button className="button p-1" onClick={this.switchAccept}>
                                        <img className="svgLabel pr-1" src={this.state.accepted === 'S' ? Question : (this.state.accepted === 'R' ? Stop : Tick)} alt="Reject/accept button" title="Reject/accept button" />
                                        <span>{this.state.accepted === 'S' ? "Submitted" : (this.state.accepted === 'R' ? "Rejected" : "Accepted")}</span>
                                    </button>
                                </div>
                                <div className={(((isAuthenticated && isMyData) || isGod) && this.state.accepted !== 'S') ? "px-4 pt-1" : "hidden"} role="group" aria-label="edit this dataset">
                                    <button className="button p-1" onClick={this.switchPublish}>
                                        <img className="svgLabel pr-1" src={this.state.published === 'N' ? Stop : Tick} alt="Reject/accept button" title="Reject/accept button" />
                                        <span>{this.state.published === 'N' ? "Hidden" : "Visible"}</span>
                                    </button>
                                </div>


                            </div>
                        </div>
                        <div className="row">
                            <div className="offset-2 col-10 mt-2">
                                {/* <div className={!this.state.expanded ? 'hidden ' : ''} > */}
                                <div className="text-right">
                                    {/* <div className="row">
                                    <p className="" style={{fontSize:"12px"}}>{item[0].Data_collection_strategy}</p>
                                </div> */}

                                    <div className="row justify-content-between pt-2">
                                        <div className="col-md-6 row">
                                            <p><span style={{ fontWeight: "700", fontSize: "12px" }}>Tags:</span> <span style={{ fontSize: "12px" }}>{item[0].Sub_category}</span></p>
                                        </div>

                                    </div>

                                    <div className="row mr-sm-4 pt-2 pb-4">
                                        {(item[0].Link_Dataset === "") ? "" : dataset}
                                        {(item[0].Link_Paper === "") ? "" : paper}
                                        {(item[0].Contact_Emails === "") ? "" : contact}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (<div></div>)
        }
    }
}
const mapStatesToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
})
export default connect(mapStatesToProps, { voteDataset, editDataset, updateDataset, myDatasets })(withRouter(DatasetCard))
