export const GET_ERRORS = "GET_ERRORS"
export const UPDATE_FILTERS = "UPDATE_FILTERS"

export const ADDED_DATASET = "ADDED_DATASET"
export const REGISTER_USER = "REGISTER_USER"


export const SET_CURRENT_USER = "SET_CURRENT_USER"
export const SET_CURRENT_USER_SIMPLE = "SET_CURRENT_USER_SIMPLE"
export const LOGOUT_USER = "LOGOUT_USER"

export const UPDATE_PASSWORD = "UPDATE_PASSWORD"
export const CHECK_TOKEN = "CHECK_TOKEN"
export const CHECK_EMAIL = "CHECK_EMAIL"
export const CHECK_TOKEN_FAIL = "CHECK_TOKEN_FAIL"
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL"

export const BAD_DATASET = "BAD_DATASET"
export const EDIT_DATASET = "EDIT_DATASET"
export const MY_DATASETS = "MY_DATASETS"
export const EDIT_DATASET_FAIL = "EDIT_DATASET_FAIL"
export const UPDATE_DATASET = "UPDATE_DATASET"
export const UPDATE_DATASET_FAIL = "UPDATE_DATASET_FAIL"
export const VOTE_DATASET = "VOTE_DATASET"
export const VOTE_DATASET_FAIL = "VOTE_DATASET_FAIL"
