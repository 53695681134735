import React, { Component } from 'react'
import TextFieldGroup from '../common/TextFieldGroup'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {checkToken, updatePassword} from '../../actions/authActions'
import PropTypes from 'prop-types'

class ResetPassword extends Component{
    constructor(){
        super()
        this.state={
          password:'',
          password2:'',
          token:"",
          errors:{}
        }
    }
    componentDidMount(){
      const {token} = this.props.match.params
      this.setState({token: token})
      this.props.checkToken({"token":token})
    }
    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }
    onSubmit = e => {
        e.preventDefault()
        const userData = {
            password: this.state.password,
            password2: this.state.password2,
            token: this.state.token
          };
        this.props.updatePassword(userData, this.props.history)
    }
    render(){
        const {errors} = this.props
        const {resetPassword} = this.props.auth
        // Get the user from state like this
        // const {user} = this.props.auth
      return (
          <div className="login">
            <div className="container">
              <div className="row">
                <div className="col-md-8 m-auto">
                  <h1 className="display-4 text-center">Forgot Password?</h1>
                  <div className="row justify-content-center"> 
              </div>
                  <p style={resetPassword ? {display:""} : {display:"none"}} className=" text-center">
                    Setup your new password
                  </p>
                  <p style={resetPassword ? {display:"none"} : {display:""}} className=" text-center">
                    Token expired
                  </p>
                  <form style={resetPassword ? {display:"initial"} : {display:"none"}} onSubmit={this.onSubmit}>
                    <TextFieldGroup
                      placeholder="Enter new password"
                      name="password"
                      type="password"
                      value={this.state.password}
                      onChange={this.onChange}
                      error={errors.password}
                    />
                    <TextFieldGroup
                      placeholder="Confirm new password"
                      name="password2"
                      type="password"
                      value={this.state.password2}
                      onChange={this.onChange}
                      error={errors.password2}
                    />
                    <input type="submit" className="btn btn-info btn-block mt-4" value="Reset Password" />
                  </form>
                </div>
              </div>
              
            </div>
          </div>
      )}
}

ResetPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}
  
  const mapStatesToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
  })

export default connect(mapStatesToProps, {checkToken, updatePassword})(withRouter(ResetPassword))