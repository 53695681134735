import React, { Component } from 'react'
import DatasetCard from '../datasets/NewCard'
import MobileDatasetCard from '../datasets/MobileCard'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { editDataset } from '../../actions/authActions'

class CardsGen extends Component {
    constructor() {
        super()
        this.state = {
            items: [],
            windowWidth: window.innerWidth
        }
        this.onChildClicked = this.onChildClicked.bind(this);
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    };

    componentDidMount() {
        this.setState({ items: this.props.items })
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    onChildClicked = (id) => {
        console.log("clicked", id)
        this.props.editDataset(id, this.props.history)
    }

    resetFilters = () => {
        this.props.resetSearchValue();
        this.props.resetItems();
    }

    render() {
        let { items } = this.props
        const { isMyData } = this.props
        const multi = (isMyData) ? 2000 : 1000
        const { windowWidth } = this.state;
        if (items.length !== 0) {
            if (windowWidth > 1200) {
                return (
                    <div className="mt-2">
                        <div>
                            {items.map((item, idx) => {
                                return <DatasetCard onClicked={this.onChildClicked} isMyData={isMyData} item={item} key={item.Dataset_Name + Math.random() * multi} />
                            })}
                        </div>
                    </div>
                )
            }
            else
                return (
                    <div className="mt-2">
                        <div>
                            {items.map((item, idx) => {
                                return <MobileDatasetCard onClicked={this.onChildClicked} isMyData={isMyData} item={item} key={item.Dataset_Name + Math.random() * multi} />
                            })}
                        </div>
                    </div>
                )
        }

        else {
            if (windowWidth > 1200) {
                return (
                    <div className="col-lg-9">
                        <div style={{ height: '60vh', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <p aria-live="polite" aria-atomic="true">No data found for your queries</p>

                            <button aria-live="polite" aria-atomic="true" type="button" className="button p-2" onClick={() => this.resetFilters()}>Reset Filters</button>
                        </div>
                    </div>
                )
            } else
                return (
                    <div style={{ width: '100vw' }}>
                        <div style={{ height: '60vh', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <p aria-live="polite" aria-atomic="true">No data found for your queries</p>

                            <button aria-live="polite" aria-atomic="true" type="button" className="button p-2" onClick={() => this.resetFilters()}>Reset Filters</button>
                        </div>
                    </div>
                )
        }
    }
}
const mapStatesToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
})
export default connect(mapStatesToProps, { editDataset })(withRouter(CardsGen))