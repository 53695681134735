import React, { Component } from 'react'
import backgroundImage from '../../images/background.jpg'
import repository from '../../images/repository.svg'
import datasets from '../../images/datasets.svg'
import accessibility from '../../images/accessibility.png'
import umdLogo from '../../images/logos/circular_umd.png'
import traceLogo from '../../images/logos/square_trace.png'
import niddilrr from '../../images/logos/logoNidilrr.jpg'
import hcilLogo from '../../images/logos/hcilLogo.png'
import iamLogo from '../../images/logos/iamLogo.png'
import CookieConsent from "react-cookie-consent";
import { PageView, initGA } from '../tracking/GoogleAnalytics'

class About extends Component {
    constructor(props) {
        super(props);
        this.state = { windowWidth: window.innerWidth };
    }
    componentDidMount() { //Q: Do you need didmount, willunmount?
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }
    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    };
    render() {
        initGA('UA-171548325-1')
        PageView()
        const { windowWidth } = this.state;
        const bigPage = (
            <div className='row justify-content-md-center '>
                <div className="col-lg-1 col-md  offset-lg-0 offset-md-1 p-3">
                    <a href="https://iam.umd.edu/">
                        <img src={iamLogo} width="60" height="60" className='img-fluid' alt="IAM Lab logo" />
                    </a>
                </div>
                <div className="col-lg-1 col-md p-3">
                    <a href="https://trace.umd.edu/">
                        <img src={traceLogo} width="60" height="60" className='img-fluid' alt="Trace center logo" />
                    </a>
                </div>
                <div className="col-lg-1 col-md p-3">
                    <a href="https://hcil.umd.edu/">
                        <img src={hcilLogo} width="60" height="60" className='img-fluid' alt="HCI Lab logo" />
                    </a>
                </div>
                <div className="col-lg-1 col-md p-3">
                    <a href="https://umd.edu/">
                        <img src={umdLogo} width="60" height="60" className='img-fluid' alt="UMD Logo" />
                    </a>
                </div>
                <div className="col-lg-2 col-md-3 offset-md-2 pt-2">
                    <a href="https://acl.gov/about-acl/about-national-institute-disability-independent-living-and-rehabilitation-research">
                        <img src={niddilrr} width="150" height="60" className='img-fluid' alt="NIDDILRR Logo" />
                    </a>
                </div>
            </div>
        )

        const smallPage = (
            <div>
                <div className='row justify-content-sm-left'>
                    <div className="col-sm-2 col-3 p-3">
                        <a href="https://iam.umd.edu/">
                            <img src={iamLogo} width="60" height="60" className='img-fluid' alt="IAM Lab logo" />
                        </a>
                    </div>
                    <div className="col-sm-2 col-3 p-3">
                        <a href="https://trace.umd.edu/">
                            <img src={traceLogo} width="60" height="60" className='img-fluid' alt="Trace center logo" />
                        </a>
                    </div>
                    <div className="col-sm-2 col-3 p-3">
                        <a href="https://hcil.umd.edu/">
                            <img src={hcilLogo} width="60" height="60" className='img-fluid' alt="HCI Lab logo" />
                        </a>
                    </div>
                    <div className="col-sm-2 col-3 p-3">
                        <a href="https://umd.edu/">
                            <img src={umdLogo} width="60" height="60" className='img-fluid' alt="UMD Logo" />
                        </a>
                    </div>
                    <div className="col-sm-3 col-5 offset-sm-1 offset-4 p-3">
                        <a href="https://acl.gov/about-acl/about-national-institute-disability-independent-living-and-rehabilitation-research">
                            <img src={niddilrr} height="135" className='img-fluid' alt="NIDDILRR Logo" />
                        </a>
                    </div>
                </div>
            </div>
        )

        var bib1 = (
            <div>
                @inproceedings&#123;Kacorri2020Data,<br />
                author = &#123;Kacorri, Hernisa and Dwivedi, Utkarsh and Kamikubo, Rie&#125;,<br />
                title = &#123;Data Sharing in Wellness, Accessibility, and Aging&#125;,<br />
                year = &#123;2020&#125;,<br />
                booktitle = &#123;NeurIPS Workshop on Dataset Curation and Security&#125;&#125;<br />
            </div>
        )
        var bib2 = (
            <div>
                @inproceedings&#123;Kacorri2020Incluset,<br />
                author = &#123;Kacorri, Hernisa and Dwivedi, Utkarsh and Amancherla, Sravya and Jha, Mayanka and Chanduka, Riya&#125;,<br />
                title = &#123;IncluSet: A Data Surfacing Repository for Accessibility Datasets&#125;,<br />
                year = &#123;2020&#125;,<br />
                isbn = &#123;9781450371032&#125;,<br />
                publisher = &#123;Association for Computing Machinery&#125;,<br />
                address = &#123;New York, NY, USA&#125;,<br />
                url = &#123;https://doi.org/10.1145/3373625.3418026&#125;,<br />
                doi = &#123;10.1145/3373625.3418026&#125;,<br />
                booktitle = &#123;The 22nd International ACM SIGACCESS Conference on Computers and Accessibility&#125;,<br />
                articleno = &#123;72&#125;,<br />
                numpages = &#123;4&#125;,<br />
                series = &#123;ASSETS '20 &#125;&#125;<br />
            </div>
        )

        var bib3 = (
            <div>
                @inproceedings&#123;Kamikubo2021Sharing,<br />
                author = &#123;Kamikubo, Rie and Dwivedi, Utkarsh and Kacorri, Hernisa&#125;,<br />
                title = &#123;Sharing Practices for Datasets Related to Accessibility and Aging&#125;,<br />
                year = &#123;2021&#125;,<br />
                isbn = &#123;9781450383066&#125;,<br />
                publisher = &#123;Association for Computing Machinery&#125;,<br />
                address = &#123;New York, NY, USA&#125;,<br />
                url = &#123;https://doi.org/10.1145/3441852.3471208&#125;,<br />
                doi = &#123;10.1145/3441852.3471208&#125;,<br />
                booktitle = &#123;The 23rd International ACM SIGACCESS Conference on Computers and Accessibility&#125;,<br />
                articleno = &#123;28&#125;,<br />
                numpages = &#123;16&#125;,<br />
                keywords = &#123;disability, repository, sharing practices, dataset, machine learning.&#125;,<br />
                location = &#123;Virtual Event, USA &#125;,<br />
                series = &#123;ASSETS '21&#125;&#125;<br />
            </div>
        )
        return (
            <div className="mx-auto">
                <div className=" container mt-5" >
                    <div className="row container ml-2 mr-2 mt-2">
                        <div className="text-justify">
                            Incluset is created and maintained by the <a style={{ color: "#000000", textDecoration: "underline" }} href="https://iam.umd.edu/">Intelligent Assistive Machines (IAM) Lab</a> led by
                            Dr. Hernisa Kacorri at the <a style={{ color: "#000000", textDecoration: "underline" }} href="https://umd.edu/">University of Maryland, College Park</a>.
                            The lab is affiliated with the <a style={{ color: "#000000", textDecoration: "underline" }} href="https://trace.umd.edu/">Trace Research & Development Center</a>, whose mission is to capitalize on the potential that technologies hold for people experiencing barriers due to disability, aging, or digital literacy and to prevent emerging technologies from creating new barriers for these individuals.
                            IncluSet is tied to this mission by supporting the research
                            community discover and link to accessibility datasets that include data generated by people with
                            disabilities and older adults. Datasets and data sharing play an important role for innovation.
                            The datasets available in IncluSet have the potential for training
                            and evaluating machine learning models, as well as benchmarking, mitigating bias, and understanding the complexity
                            of real world AI-infused applications for people with disabilities.
                        </div>
                    </div>
                    {/* <div className="row ml-2 mt-2">
                    <div className="text-vertical-center">
                        This work is supported by the National Institute on Disability, Independent Living, and 
                        Rehabilitation Research(NIDILRR), ACL, HHS (#90REGE0008).
                    </div>
                </div> */}

                    <div className="row container mt-4 ml-2 mr-5">
                        <div className="text-vertical-center">
                            <h5>Contact</h5>
                            For any inquries, please contact us at <span className="text-monospace">incluset(at)umd(dot)edu</span>
                        </div>
                    </div>

                    <div className="row container mt-4 ml-2 mr-5">
                        <div className="text-vertical-center">
                            <h5>Citation Policy</h5>
                            Please refer to our paper(s) if you publish material based on datasets surfaced from this repository.

                            <div className="text-vertical-center p-4 mr-3">

                                <div className=""><p>Rie Kamikubo, Utkarsh Dwivedi, Hernisa Kacorri, "Sharing Practices for Datasets Related to Accessibility and Aging," In The 23rd International ACM SIGACCESS Conference on Computers and Accessibility (ASSETS '21), October 2021.</p>
                                    <button type="button" className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#bib3">
                                        BIBTex Citation
                                    </button></div>

                                <div className="modal fade" id="bib3" tabIndex="-1" role="dialog" aria-labelledby="Sharing Practices for Datasets Related to Accessibility and Aging" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-body">
                                                {bib3}
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-3"><p>Hernisa Kacorri, Utkarsh Dwivedi, Rie Kamikubo, "Data Sharing in Wellness, Accessibility, and Aging," In NeurIPS Workshop on Dataset Curation and Security, December 2020.</p>
                                    <button type="button" className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#bib1">
                                        BIBTex Citation
                                    </button></div>

                                <div className="modal fade" id="bib1" tabIndex="-1" role="dialog" aria-labelledby="Data Sharing in Wellness, Accessibility, and Aging" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-body">
                                                {bib1}
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="mt-3 "><p>Hernisa Kacorri, Utkarsh Dwivedi, Sravya Amancherla, Mayanka Jha, and Riya Chanduka, "IncluSet: A Data Surfacing Repository for Accessibility Datasets," In The 22nd International ACM SIGACCESS Conference on Computers and Accessibility (ASSETS '20), October 2020.</p>
                                    <button type="button" className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#bib2">
                                        BIBTex Citation
                                    </button></div>

                                <div className="modal fade" id="bib2" tabIndex="-1" role="dialog" aria-labelledby="IncluSet: A Data Surfacing Repository for Accessibility Datasets" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-body">
                                                {bib2}
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="row container mt-3 mb-3 ml-2 mr-5">
                        <div className="text-vertical-center">
                            <h5>Privacy Policy</h5>
                            Please check our policy regarding information that we collect about you when you log in to IncluSet and link new datasets.
                            <div className="text-vertical-center p-4">
                                <button type="button" className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#privacy">
                                    Check Policy
                                </button>
                            </div>

                            <div className="modal fade" id="privacy" tabIndex="-1" role="dialog" aria-labelledby="Privacy Policy" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h6 className="modal-title" id="Privacy Policy">Privacy Policy Effective: January 1, 2021</h6>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body small">
                                            <p>IncluSet operates <span className="text-monospace">https://incluset.com/</span>. By using IncluSet, you agree to the collection and use of information in accordance with this policy.</p>

                                            <h6>Information Collection</h6>
                                            <p>We collect information you provide directly to us when you create an account or log in using Google on IncluSet, or fill out a form to link information about your project/dataset to IncluSet.
                                                We ask for basic information from you in order to set up your account.
                                                The types of personal information we may collect include your name, username, and email address.
                                                IncluSet integrates Google Sign-In which we receive the authorization code for your login from Google. We never receive your password. </p>

                                            <p>Like many online service providers, we collect information that your browser sends whenever you visit IncluSet.
                                                This "Log Data" may include information such as the browser type, IP address, the pages of IncluSet that you visit, the time and date of your visit, the time spent on those pages and other statistics.</p>

                                            <p>Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. </p>

                                            <h6>Information Use</h6>
                                            <p>We use information about you for the purpose of ensuring quality, mainting safety, and improving IncluSet. We ask for your login details to link new datasets in order to monitor the quality of datasets found in our repository. </p>

                                            <h6>Security</h6>
                                            <p>The security of your personal information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure.
                                                While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security. We store personal information for only as long as we have a reason to keep it.</p>

                                            <p>If you have any questions about this Privacy Policy, please contact us.</p>

                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {(windowWidth > 800) ? bigPage : smallPage}

                </div>
            </div>
        )
    }
}
export default About