import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom'
import {Provider} from 'react-redux'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Navbar from "./components/layout/Navbar"
import Footer from './components/layout/Footer';
import Datasets from './components/layout/Datasets';
import NewLanding from './components/layout/NewLanding';
import Register from './components/auth/Register';
import AddDataset from './components/datasets/NewAddDataset';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';

import setAuthToken from '../src/utils/setAuthToken'
import {loginGoogleUser, logoutUser} from '../src/actions/authActions'
import CombinedLogin from './components/auth/CombinedLogin';
import EditDataset from './components/datasets/NewEditDataset';
import About from './components/layout/About';


if(localStorage.getItem('jwtToken')){

  const data = JSON.parse(localStorage.getItem('jwtToken'))
  console.log(data.accessToken)
  if(data.accessToken !== undefined){
    const currentTime = Date.now()/1000
    if(data.tokenObj.expires_at > currentTime){
      setAuthToken(data.accessToken)
      store.dispatch(loginGoogleUser(data))
    }
    else{
      console.log("expired")
      setAuthToken(false)
      store.dispatch(logoutUser())
    }
  }
  else{
    console.log("not found")
    setAuthToken(false)
    store.dispatch(logoutUser())
  }
}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Navbar/>
            <Route exact path="/" component={NewLanding}/>
            <Route exact path="/datasets" component={Datasets}/>
            <Route exact path="/login" component={CombinedLogin}/>
            <Route exact path="/about" component={About}/>
            <Route exact path="/register" component={Register}/>
            <Route exact path="/add" component={AddDataset}/>
            <Route exact path="/editDataset" component={EditDataset}/>
            <Route exact path="/forgot" component={ForgotPassword}/>
            <Route path="/reset/:token" component={ResetPassword}/>
          <Footer/>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
